import { InteractionType } from '@azure/msal-browser'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import configureStore from './bakery/store/configureStore'
import ErrorRefresh from './common/components/ErrorRefresh'
import Loading from './common/components/Loading'
import Navigation from './common/components/Navigation'
import { setUser } from './common/Context/commonDispatch'
import {
  Context,
  initialContext,
  useImmerReducer,
} from './common/Context/context'
import { Reducer } from './common/Context/reducer'
import { IContext } from './common/types/IContext'

const store = configureStore()

const App = () => {
  const [state, dispatch]: [unknown, React.Dispatch<unknown>] = useImmerReducer(
    Reducer,
    initialContext,
  )

  const { accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { login, error } = useMsalAuthentication(InteractionType.Redirect)

  useEffect(() => {
    if (isAuthenticated && inProgress === 'none') {
      if (accounts && accounts.length > 0) {
        dispatch(
          setUser({
            fullname: accounts[0].name as string,
            email: accounts[0].username,
          }),
        )
      }
    }
  }, [isAuthenticated, accounts, dispatch, inProgress])

  return (
    <>
      {isAuthenticated && inProgress === 'none' ? (
        <Provider store={store}>
          <Context.Provider
            key='c-context-provider'
            value={{ state: state as IContext, dispatch }}
          >
            <Navigation />
          </Context.Provider>
        </Provider>
      ) : (
        <Loading message={inProgress as string} />
      )}

      {error && (
        <ErrorRefresh
          message={error?.errorMessage}
          actions={[
            {
              handler: () => login(InteractionType.Redirect),
              title: 'Try re-login',
            },
          ]}
        />
      )}
    </>
  )
}

export default App
