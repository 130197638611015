import IScrollPosition from '../../types/IScrollPosition'
import { actions } from './actions'

interface InitialState {
  scrollPositions: IScrollPosition[]
}

const initialState: InitialState = {
  scrollPositions: [],
}

interface IScrollPositionsReducer {
  scrollPosition: IScrollPosition
  scrollPositionClassName: string
  type: string
}

const scrollPositionsReducer = (
  state = initialState,
  action: IScrollPositionsReducer,
) => {
  switch (action.type) {
    case actions.set:
      const newPosition: IScrollPosition = action.scrollPosition
      const indexOfExisting = findIndexByClassName(
        state.scrollPositions,
        newPosition.className,
      )
      if (indexOfExisting === -1) {
        const clonedScrollPositions = [...state.scrollPositions, newPosition]
        return { ...state, scrollPositions: clonedScrollPositions }
      }
      const clonedScrollPositionsForUpdate = [...state.scrollPositions]
      clonedScrollPositionsForUpdate[indexOfExisting].position =
        newPosition.position
      return { ...state, scrollPositions: clonedScrollPositionsForUpdate }

    case actions.clear:
      const indexOfPositionToDelete = findIndexByClassName(
        state.scrollPositions,
        action.scrollPositionClassName,
      )
      if (indexOfPositionToDelete !== -1) {
        const clonedScrollPositionsForDelete = [...state.scrollPositions]
        clonedScrollPositionsForDelete.splice(indexOfPositionToDelete, 1)
        return { ...state, scrollPositions: clonedScrollPositionsForDelete }
      }
      return state

    default:
      return state
  }
}

const findIndexByClassName = (
  scrollPositions: IScrollPosition[],
  className: string,
) => scrollPositions.findIndex((item) => item.className === className)

export default scrollPositionsReducer
