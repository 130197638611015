import '../../scss/common/components/header.scss'

import { useMsal } from '@azure/msal-react'
import { OpenButton } from '@jsainsburyplc/retail-stateful-tutorial'
import {
  Header as JsHeader,
  HeaderGlobalBar,
  HeaderGlobalItem,
  HeaderItem,
  HeaderLogo,
  HeaderMainBar,
  HeaderNav,
} from '@jsluna/react'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { getFirstName } from '../../utils/colleagueDetails'
import { getLocalStorePlanners } from '../../utils/localPlanner'
import { getUserStore } from '../../utils/localStore'
import { Context } from '../Context/context'
import { HeaderNavItem } from '../enums/HeaderItems'
import { Menu } from '../enums/MenuEnum'
import { isTutorialEnabled } from '../enums/TutorialVersion'
import { IStore } from '../types/IStore'
import { IStorePlanners } from '../types/IStorePanners'
import { IUser } from '../types/IUser'

interface HeaderMenuProps {
  title: string
  navItems?: HeaderNavItem[]
  activeMenuType?: Menu
}

const Header = (props: HeaderMenuProps) => {
  const { state } = useContext(Context)
  const [store] = useState<IStore>(getUserStore())
  const [storePlanners] = useState<IStorePlanners>(getLocalStorePlanners())
  const { instance } = useMsal()

  const getSelectedMenuClassName = (menuItem: Menu) => {
    if (props.activeMenuType === menuItem) {
      return 'is-active ln-c-navigation__link'
    }
    return 'ln-c-navigation__link'
  }

  const renderUsername = () => {
    const user = state.user as IUser
    const firstname = getFirstName(user.fullname)
    return (
      <span className='c-common-header-action-spacing'>
        {`Hello ${firstname}`}
      </span>
    )
  }

  const renderPlannerSelection = () => (
    <span className='c-common-header-action-spacing ln-c-link'>
      <Link
        to={{
          pathname: '/plannerselection',
          search: '?reset=true',
        }}
      >
        Switch planner
      </Link>
    </span>
  )

  const renderStoreSelection = () => (
    <span className='c-common-header-action-spacing' data-testid='store'>
      <Link
        className='c-common-header-store-label'
        to={{
          pathname: '/storesetting',
          search: '?reset=true',
        }}
      >
        Store:{' '}
        <span className='c-common-header-store-number'>{`${store.storeCode}`}</span>
      </Link>
    </span>
  )

  const renderTutorial = () =>
    isTutorialEnabled ? (
      <span className='c-common-header-action-spacing' data-testid='tutorial'>
        <OpenButton>
          {(showTutorial: () => void) => (
            <a
              href='#'
              className='c-common-header-store-number'
              onClick={() => showTutorial()}
            >
              What's New
            </a>
          )}
        </OpenButton>
      </span>
    ) : (
      <></>
    )

  const renderLogout = () => (
    <span className='c-common-header-action-spacing' data-testid='logout'>
      <a href='#' onClick={() => void instance.logoutRedirect()}>
        Logout
      </a>
    </span>
  )

  return (
    <JsHeader divided className='c-common-hide-in-print'>
      <HeaderGlobalBar>
        <HeaderGlobalItem>
          <HeaderLogo
            className='ln-u-display-block@nav c-common-header-title'
            data-testid='headerTitle'
          >
            {props.title}
          </HeaderLogo>
        </HeaderGlobalItem>
        <HeaderGlobalItem align='right'>
          {renderUsername()}
          {storePlanners.count > 1 ? renderPlannerSelection() : <></>}
          {renderTutorial()}
          {renderStoreSelection()}
          {renderLogout()}
        </HeaderGlobalItem>
      </HeaderGlobalBar>

      <HeaderMainBar>
        <HeaderLogo className='ln-u-display-none@nav' data-testid='headerTitle'>
          {props.title}
        </HeaderLogo>
        <HeaderNav drawer label='Main'>
          {props.navItems &&
            props.navItems.map((item) => (
              <Link
                key={item.id}
                to={item.to}
                className={getSelectedMenuClassName(item.type)}
                data-testid={item.id}
              >
                {item.text}
              </Link>
            ))}
        </HeaderNav>
        <HeaderItem drawer='footer' className='ln-u-display-none@nav ln-u-soft'>
          <div>{renderUsername()}</div>
          <div>
            {storePlanners.count > 1 ? renderPlannerSelection() : <></>}
          </div>
          <div>{renderTutorial()}</div>
          <div>{renderStoreSelection()}</div>
          <div>{renderLogout()}</div>
        </HeaderItem>
      </HeaderMainBar>
    </JsHeader>
  )
}

export default Header
