import { PlannerName } from '../enums/PlannerNameEnum'
import { IStore } from '../types/IStore'
import { IStorePlanners } from '../types/IStorePanners'
import { ApiClient } from './apiClient'

export const getStores = async (apiClient: ApiClient): Promise<IStore[]> =>
  await apiClient.get<IStore[]>(PlannerName.None, 'stores')

export const getStoreCounters = async (
  apiClient: ApiClient,
  storeId: number,
): Promise<IStorePlanners> =>
  await apiClient.get<IStorePlanners>(
    PlannerName.None,
    `stores/${storeId}/counters`,
  )
