import React from 'react'

interface IPluCodesProps {
  pluCodes: string[]
}

const QpigPluCode = ({ pluCodes }: IPluCodesProps) => {
  const getPluCode = () => {
    if (pluCodes === null || pluCodes === undefined) {
      return
    }

    if (pluCodes.length === 1) {
      return (
        <div data-testid='plu-code'>
          <span className='ln-u-font-weight-bold ln-u-margin-top'>
            {' '}
            PLU Code:{' '}
          </span>
          {pluCodes[0]}
        </div>
      )
    } else {
      return pluCodes.map((pluCode: string, j) => {
        let separatorIndex = pluCode.indexOf(':')
        if (separatorIndex === -1) {
          separatorIndex = pluCode.indexOf('-')
        }
        const pluCodeName = pluCode.substring(0, separatorIndex)
        const pluCodeValue = pluCode.substring(
          separatorIndex + 1,
          pluCode.length,
        )
        return (
          <div
            className='c-qpig-pluCode ln-u-margin-top'
            data-testid='plu-code'
            key={j}
          >
            <b>PLU Code {pluCodeName}</b>: {pluCodeValue}
          </div>
        )
      })
    }
  }

  return <> {getPluCode()} </>
}

export default QpigPluCode
