import { ISku } from '../../common/types/ISku'
import { PlannerName } from '../enums/PlannerNameEnum'
import { ISkusQueryResult } from '../types/ISkusQueryResult'
import { ApiClient } from './apiClient'

export const getSkus = async (
  apiClient: ApiClient,
  planner: string,
  storeId: number,
): Promise<ISku[]> =>
  (
    await apiClient.get<ISkusQueryResult>(
      PlannerName.None,
      `skus/${planner}/${storeId}`,
    )
  ).skus
