import { IOvenSettingDetail } from '../../types/IOvenSettingDetail'

interface IOvenTypeDescriptionProps {
  ovenSettingDetail?: IOvenSettingDetail
}

const OvenTypeDescription = ({
  ovenSettingDetail,
}: IOvenTypeDescriptionProps) => {
  return (
    <>
      {ovenSettingDetail && (
        <p>
          {ovenSettingDetail.ovenTypeDescription ? 'Convection oven' : ''} P
          {ovenSettingDetail.ovenSettingId}
        </p>
      )}
    </>
  )
}

export default OvenTypeDescription
