import { FormErrorMessageKey } from '../enums/FormErrorMessageKey'

export const FormErrorMessages = new Map<string, string>()
  .set(
    FormErrorMessageKey.EmptyOrNoDecimalTemperature,
    `Please complete all missing fields before submitting your readings and 
      make sure they all have a decimal point.`,
  )
  .set(
    FormErrorMessageKey.Under20DegTemperature,
    `Please review readings as we’ve detected a possible error. 
      Continue to submit if readings are accurate.`,
  )
