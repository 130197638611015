import moment from 'moment'

import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../utils/localStore'
import { IMix } from '../types/IMix'
import IMixSkuUpdate from '../types/IMixSkuUpdate'
import IMixStatusUpdate from '../types/IMixStatusUpdate'

const getMixes = async (
  apiClient: ApiClient,
  planDate: Date,
): Promise<IMix[]> =>
  await apiClient.get<IMix[]>(
    PlannerName.Bakery,
    `mix/${getUserStore().storeId}/${moment(planDate).format('YYYY-MM-DD')}/0`,
  )

const getMixSkus = async (
  apiClient: ApiClient,
  planDate: Date,
  mixId: number,
): Promise<IMix[]> =>
  await apiClient.get<IMix[]>(
    PlannerName.Bakery,
    `mix/${getUserStore().storeId}/${moment(planDate).format('YYYY-MM-DD')}/${mixId}`,
  )

const updateMixStatus = async (
  apiClient: ApiClient,
  planId: number,
  mixId: number,
  mixStatusStageId: number,
  mixSkuUpdateList: IMixSkuUpdate[],
  isMixerClean: boolean,
  rowVersion: string,
) => {
  const request: IMixStatusUpdate = {
    isMixerClean,
    mixId,
    mixStatusStageId,
    planId,
    rowVersion,
    updateMixSkus: mixSkuUpdateList,
  }

  const body = JSON.stringify(request)
  return await apiClient.post(PlannerName.Bakery, 'mix/updateMixStatus', body)
}

const updateMixSkus = async (
  apiClient: ApiClient,
  mixId: number,
  mixSkuUpdateList: IMixSkuUpdate[],
) => {
  const body = JSON.stringify(mixSkuUpdateList)
  return await apiClient.post(
    PlannerName.Bakery,
    `mix/updateMixSku/${mixId}`,
    body,
  )
}

export { getMixes, getMixSkus, updateMixSkus, updateMixStatus }
