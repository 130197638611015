import 'core-js/es/array/find'
import 'react-app-polyfill/ie11'
import './scss/index.scss'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React from 'react'
import ReactDOM from 'react-dom'

import { apiConfig } from './apiConfig'
import App from './App'
import { msalConfig } from './authConfig'
import { AppProvider } from './common/AppContext/appContext'
import * as serviceWorker from './serviceWorker'

// create PublicClientApplication instance
const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AppProvider apiScope={apiConfig.apiClientScope as string}>
      <App />
    </AppProvider>
  </MsalProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
void serviceWorker.unregister()
