import '../../../scss/common/components/qpig.scss'
import './QpigProcessSteps'

import {
  GridItem,
  GridWrapper,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import React from 'react'

import { IAdditionalInformation } from '../../types/IAdditionalInformation'
import { QpigTypes } from '../../types/IQpigTypes'
import QpigPLUCode from './QpigPLUCode'

interface IQpigProcessStepsTableProps {
  additionalInformationList: IAdditionalInformation[]
  pluCodes: string[]
}

const QpigPackagingCodeTable = ({
  additionalInformationList,
  pluCodes,
}: IQpigProcessStepsTableProps) => {
  const hasPackagingCodes = (): boolean => {
    if (additionalInformationList) {
      return additionalInformationList.some(
        (additionalInformation) =>
          additionalInformation.type === QpigTypes.QpigPackagingCodes,
      )
    } else {
      return false
    }
  }

  const getPackagingCodes = () => {
    if (
      additionalInformationList === null ||
      additionalInformationList === undefined
    ) {
      return <tr />
    }
    return additionalInformationList
      .filter(
        (additionalInformation) =>
          additionalInformation.type === QpigTypes.QpigPackagingCodes,
      )
      .map((additionalInformation, i) => {
        const rawPackagingQpig = additionalInformation.description
        // Removes the QPIG Packaging Code and then the following Number.
        const packagingDescription = rawPackagingQpig
          .replace(/^QPIG Packaging Code \d+/, '')
          .replace(/((\d+\/\d+)|(\d+))$/, '')

        // Checks final part of the string for numbers or numbers seperated by /.
        const skuIndex = rawPackagingQpig.search(
          /((\d+\/\d+)|(\d+)|([sS]upplied in product case))$/,
        )
        const packagingCode = rawPackagingQpig
          .substring(skuIndex)
          .replace(/([sS]upplied in product case)$/, '-')

        return (
          <TableRow data-testid='packaging-code-row' key={i}>
            <TableCell>{packagingDescription}</TableCell>
            <TableCell>{packagingCode}</TableCell>
          </TableRow>
        )
      })
  }

  const tablePackagingCodes = () => (
    <GridWrapper>
      <GridItem size={{ sm: '1/1', md: '1/2', lg: '1/3' }}>
        <TableContainer>
          <TableHeader>
            <TableRow data-testid='packaging-code-row'>
              <TableCell colSpan={2}>Packaging codes</TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>{getPackagingCodes()}</TableBody>
        </TableContainer>
      </GridItem>
    </GridWrapper>
  )

  return (
    <div className='c-qpig-table-margin'>
      {hasPackagingCodes() && tablePackagingCodes()}
      <div className='ln-u-margin*2'>
        <QpigPLUCode pluCodes={pluCodes} />
      </div>
    </div>
  )
}

export default QpigPackagingCodeTable
