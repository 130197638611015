import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  OutlinedButton,
} from '@jsluna/react'
import React from 'react'

interface IProps {
  handleClose: () => void
  handleSave: () => void
  isOpen: boolean
}

const DeleteBatchModal = (props: IProps) => (
  <Modal
    small
    handleClose={() => props.handleClose()}
    open={props.isOpen}
    headingId='info-modal'
  >
    <ModalHeading element='h4'>Delete batch</ModalHeading>

    <div>Are you sure you would like to delete this batch?</div>

    <ButtonGroupWrapper>
      <ButtonGroupSecondary className='u-full-width'>
        <GridWrapper>
          <GridItem
            className=''
            size={{
              default: '1/1',
              xs: '1/2',
              sm: '1/2',
              md: '1/2',
              lg: '1/2',
            }}
          >
            <FilledButton
              className='u-full-width'
              onClick={() => props.handleSave()}
            >
              Yes
            </FilledButton>
          </GridItem>

          <GridItem
            className='c-batch-card-modal-secondary-button'
            size={{
              default: '1/1',
              xs: '1/2',
              sm: '1/2',
              md: '1/2',
              lg: '1/2',
            }}
          >
            <OutlinedButton
              className='u-full-width'
              onClick={() => props.handleClose()}
            >
              No
            </OutlinedButton>
          </GridItem>
        </GridWrapper>
      </ButtonGroupSecondary>
    </ButtonGroupWrapper>
  </Modal>
)

export default DeleteBatchModal
