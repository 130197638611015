import { produce } from 'immer'
import React from 'react'

import { PlannerName } from '../enums/PlannerNameEnum'
import { IContext } from '../types/IContext'
import { Actions } from './reducer'

export const initialContext: IContext = {
  user: {
    fullname: '',
    email: '',
  },
  bannerMessages: [],
  forecast: {
    editing: false,
    forecastPlan: {
      eventName: '',
      forecastPlanDate: new Date(),
      forecastPlanId: 0,
      forecastPlannerGroups: [],
    },
    isFuture: false,
    isSaveModalOpen: false,
    planDates: [],
    selectedPlanDateIndex: 6,
  },
  ingredients: [],
  planner: PlannerName.None,
  production: {
    addToTotal: false,
    eventName: '',
    frozenProductionPlannerGroups: [],
    planDate: new Date(),
    planId: 0,
    productionPlannerGroups: [],
  },
  unsavedChanges: false,
}

export const Context = React.createContext<{
  state: IContext
  dispatch: React.Dispatch<Actions>
}>({
  dispatch: () => undefined,
  state: initialContext,
})

export const useImmerReducer = (reducer: any, initialState: IContext) =>
  React.useReducer(produce(reducer), initialState)
