import { TableBody, TableCell, TableContainer, TableRow } from '@jsluna/react'
import React from 'react'

const rows: string[][] = [
  ['1', '2'],
  ['3', '4'],
]

const CabinetDrawing = () => (
  <div className='c-hotfood-cabinet-table-section'>
    <div className='ln-u-margin-top*2 ln-u-margin-bottom ln-u-text-align-center ln-u-text'>
      Customer
    </div>
    <TableContainer
      responsive={false}
      className='.c-hotfood-cabinet-table-container'
    >
      <TableBody>
        {rows.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <TableCell
                key={`${rowIndex}-${cellIndex}`}
                className='c-hotfood-cabinet-table'
              >
                {`${cell}.`}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
    <div className='ln-u-margin-top ln-u-margin-bottom*2 ln-u-text-align-center'>
      You
    </div>
  </div>
)

export default CabinetDrawing
