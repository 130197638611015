import { Card, GridItem, GridWrapper, OutlinedButton } from '@jsluna/react'
import moment, { MomentInput } from 'moment'
import React from 'react'

import { getUserStore } from '../../../utils/localStore'
import { ICounterTemperaturesResponse } from '../../types/ICounterTemperaturesResponse'
import CounterTemperatureTable from './CounterTemperatureTable'
import { SummaryCard } from './SummaryCard'

moment.locale('en-GB')

interface IProps {
  batchDate: MomentInput
  counterTemperatureResponse: ICounterTemperaturesResponse
  isHotFoodProbeFeature: boolean
}

const CounterView = (props: IProps) => {
  const renderSummary = (text: string, value: string, style: string) => (
    <GridItem size={{ default: '1/1', md: '1/2', lg: '1/3' }}>
      <SummaryCard text={text} value={value} style={style} />
    </GridItem>
  )

  const getPrintFooter = () => {
    const store = getUserStore()
    const printDateTime = new Date()
    return `Report generated
     at ${printDateTime.getDate()}/${printDateTime.getMonth() + 1}/${printDateTime.getFullYear()} 
    ${printDateTime.getHours()}:${printDateTime.getMinutes()} for store ${store.storeId}-${store.storeName}.`
  }

  return (
    <>
      <Card className='ln-u-margin-bottom c-common-only-visible-in-print'>
        <div className='ln-u-font-weight-bold'>
          {`Counter temperature report from the ${moment(props.batchDate).format('DD/MM/YYYY')}`}
        </div>
      </Card>

      {props.counterTemperatureResponse.counterTemperatures.filter(
        (cabinets) => cabinets.cabinetTemperatures.length > 0,
      ).length > 0 && (
        <GridWrapper>
          {renderSummary(
            'Checks completed on time',
            `${props.counterTemperatureResponse.checksCompletedOnTime}%`,
            props.counterTemperatureResponse.checksCompletedOnTime > 0
              ? 'ln-u-color-green'
              : 'ln-u-color-red',
          )}
          {renderSummary(
            'Checks overdue',
            `${props.counterTemperatureResponse.checksOverdue}%`,
            props.counterTemperatureResponse.checksOverdue > 0
              ? 'ln-u-color-red'
              : 'ln-u-color-green',
          )}
          {props.isHotFoodProbeFeature &&
            renderSummary(
              'Manual temperature checks',
              props.counterTemperatureResponse.manualTemperatureChecks.toString(),
              '',
            )}
        </GridWrapper>
      )}

      {props.counterTemperatureResponse.counterTemperatures.map(
        (counterTemperature, index) =>
          (counterTemperature.cabinetSummary.length > 0 ||
            counterTemperature.cabinetTemperatures.length > 0) && (
            <div key={index}>
              <CounterTemperatureTable
                counterTemperature={counterTemperature}
                isHotFoodProbeFeature={props.isHotFoodProbeFeature}
              />
            </div>
          ),
      )}

      <Card className='ln-u-margin-top'>
        <div className='ln-u-margin-left*2 c-common-only-visible-in-print'>
          {getPrintFooter()}
        </div>
        <OutlinedButton
          className='ln-u-soft-sides c-common-hide-in-print'
          onClick={() => window.print()}
        >
          Print Report
        </OutlinedButton>
      </Card>
    </>
  )
}

export default CounterView
