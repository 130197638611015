import { Search } from '@jsluna/icons'
import { Sainsburys, withLabel } from '@jsluna/images'
import {
  AsyncAutocompleteField,
  Container,
  FilledButton,
  GridItem,
  GridWrapper,
  TextButton,
} from '@jsluna/react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { getUserStore, setUserStore } from '../../utils/localStore'
import { getStores } from '../api/storeApi'
import { useApiClient } from '../AppContext/appContext'
import { IStore } from '../types/IStore'
import Loading from './Loading'

const StoreSetting = (props: RouteComponentProps) => {
  const [confirmEnabled, setConfirmEnabled] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [stores, setStores] = useState<IStore[]>([])
  const [tempStore, setTempStore] = useState<IStore>({
    storeId: 0,
    storeCode: '',
    storeName: '',
  })

  const apiClient = useApiClient()

  useEffect(() => {
    if (props.location.search !== '?reset=true') {
      const store = getUserStore()
      if (store.storeId !== 0) {
        setIsLoading(false)
        props.history.push('/plannerselection')
        return
      }
    }

    getStores(apiClient)
      .then((res) => setStores(res))
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }, [])

  const getStoreNameToDisplay = () => {
    const storeOptions = stores.map((store: IStore) => ({
      label: `${store.storeCode} ${store.storeName}`,
      value: store.storeId.toString(),
    }))
    return new Promise((resolve) => {
      resolve(storeOptions)
    })
  }

  const onStoreSelect = (e: { label: string; value: string }) => {
    if (e === null) {
      setConfirmEnabled(false)
      return
    }
    const store = stores.filter((s: IStore) => s.storeId === Number(e.value))
    if (store.length > 0) {
      setConfirmEnabled(true)
      setTempStore(store[0])
    } else {
      setIsError(true)
    }
  }

  const onDone = () => {
    setUserStore(tempStore)
    props.history.push('/plannerselection')
  }

  const goBack = () => window.history.back()
  const goError = () => props.history.push('/error')

  const SainsburysLogo = withLabel(Sainsburys)

  return (
    <Container className='c-common-full-page-center'>
      <GridWrapper>
        <GridItem size='1/1' className='ln-u-text-align-center '>
          <SainsburysLogo label='Sainsburys Group plc' width='180px' />
        </GridItem>
        <GridItem size='1/1' className='ln-u-text-align-center'>
          <h1 className='c-title'>Production Planning</h1>
        </GridItem>

        {isLoading && <Loading />}

        {isError && goError()}

        {!isLoading && (
          <GridWrapper>
            <GridItem
              size={{ md: '1/3', sm: '1/2', default: '3/4' }}
              offsetRight={{ md: '1/3', sm: '1/4', default: '1/8' }}
              className='ln-u-margin-top*4'
            >
              <GridWrapper>
                <GridItem size='1/1'>
                  <AsyncAutocompleteField
                    icon={<Search />}
                    label=''
                    name='store-search'
                    placeholder='Search for your store...'
                    loadOptions={() => getStoreNameToDisplay()}
                    onSelect={onStoreSelect}
                    minChars={3}
                  />
                </GridItem>

                <GridItem size='1/2' className='ln-u-push-top'>
                  <FilledButton
                    disabled={!confirmEnabled}
                    fullWidth
                    onClick={onDone}
                  >
                    Save
                  </FilledButton>
                </GridItem>

                <GridItem size='1/2' className='ln-u-push-top'>
                  <TextButton fullWidth onClick={() => goBack()}>
                    Cancel
                  </TextButton>
                </GridItem>
              </GridWrapper>
            </GridItem>
          </GridWrapper>
        )}
      </GridWrapper>
    </Container>
  )
}

export default StoreSetting
