import { ErrorCircle, Tick } from '@jsluna/icons'
import {
  ButtonGroupWrapper,
  Card,
  FilledButton,
  GridItem,
  GridWrapper,
  Link,
  OutlinedButton,
  TextButton,
} from '@jsluna/react'
import React, { useEffect, useState } from 'react'

import ActionBanner from '../../../common/components/Banners/ActionBanner'
import { getUserStore } from '../../../utils/localStore'
import { FormErrorMessageKey } from '../../enums/FormErrorMessageKey'
import { TempCheckCounterStatus } from '../../enums/TempCheckCounterStatus'
import * as TemperatureCheckPosition from '../../enums/TemperatureCheckPosition'
import { ICounterProps } from '../../types/ICounterProps'
import { ITemperatureCheckValidationProps } from '../../types/ITemperatureCheckValidationProps'
import { ITemperatureData } from '../../types/ITemperatureData'
import { validateTemperatures } from '../../utils/hotFoodUtils'
import TemperatureCheckErrorMessage from '../common/TemperatureCheckErrorMessage'
import CounterTempCheckInputCard from './CounterTempCheckInputCard'

const CounterCard = (props: ICounterProps) => {
  const [temperatures, setTemperatures] = useState<ITemperatureData[]>([
    { position: 1, temperature: '' },
    { position: 2, temperature: '' },
    { position: 3, temperature: '' },
    { position: 4, temperature: '' },
  ])
  const [validation, setValidation] =
    useState<ITemperatureCheckValidationProps>({
      errorData: [],
      isLowReadingWarned: false,
      messageKey: FormErrorMessageKey.NONE,
      result: true,
    })

  useEffect(() => {
    const maxShelfNumber = Math.max(
      ...(props.shelves?.map((shelf) => shelf.shelfNumber) || []),
    )

    if (maxShelfNumber === 5) {
      setTemperatures([
        { position: 1, temperature: '' },
        { position: 2, temperature: '' },
        { position: 3, temperature: '' },
        { position: 4, temperature: '' },
        { position: 5, temperature: '' },
      ])
    } else {
      setTemperatures([
        { position: 1, temperature: '' },
        { position: 2, temperature: '' },
        { position: 3, temperature: '' },
        { position: 4, temperature: '' },
      ])
    }
  }, [props.shelves, props.isBluetoothReading])

  const onChangeFaultyOrFixed = () => {
    props.handleFaultyOrFixed({
      counterNumber: props.counterNumber,
      isFaulty: !props.isFaulty,
      shelves: props.shelves,
      storeId: getUserStore().storeId,
      turboServe: props.isTurboServe,
    })
  }

  const onSave = async () => {
    const [errorData, messageKey, result] = validateTemperatures(temperatures)
    setValidation({
      errorData: errorData as ITemperatureData[],
      isLowReadingWarned:
        !validation.isLowReadingWarned &&
        !result &&
        messageKey === FormErrorMessageKey.Under20DegTemperature,
      messageKey: messageKey as FormErrorMessageKey,
      result: result as boolean,
    })

    if (!result && !validation.isLowReadingWarned) {
      return
    }
    await props.handleSubmit(
      props.counterNumber,
      temperatures,
      props.isBluetoothReading,
    )
  }

  const renderButtons = () => (
    <ButtonGroupWrapper>
      <FilledButton className='ln-u-margin-left' onClick={() => onSave()}>
        Submit readings
      </FilledButton>
      <OutlinedButton className='ln-u-margin-left*3' onClick={handleClear}>
        Cancel
      </OutlinedButton>
      <TextButton
        className='c-counter-card-faulty-button ln-u-margin-right'
        onClick={() => onChangeFaultyOrFixed()}
      >
        Mark cabinet as faulty
      </TextButton>
    </ButtonGroupWrapper>
  )

  const handleClear = () => {
    setTemperatures([
      {
        position: props.isTurboServe
          ? TemperatureCheckPosition.TurboShelf.Top
          : TemperatureCheckPosition.CabinetWindow.CustomerRight,
        temperature: '',
      },
      {
        position: props.isTurboServe
          ? TemperatureCheckPosition.TurboShelf.Second
          : TemperatureCheckPosition.CabinetWindow.CustomerLeft,
        temperature: '',
      },
      {
        position: props.isTurboServe
          ? TemperatureCheckPosition.TurboShelf.Third
          : TemperatureCheckPosition.CabinetWindow.YouLeft,
        temperature: '',
      },
      {
        position: props.isTurboServe
          ? TemperatureCheckPosition.TurboShelf.Fourth
          : TemperatureCheckPosition.CabinetWindow.YouRight,
        temperature: '',
      },
      {
        position: props.isTurboServe
          ? TemperatureCheckPosition.TurboShelf.Fifth
          : 5,
        temperature: '',
      },
    ])

    setValidation({
      errorData: [],
      isLowReadingWarned: false,
      messageKey: FormErrorMessageKey.NONE,
      result: true,
    })
  }

  const renderCardHeader = () => (
    <div
      className={`ln-u-padding-top ln-u-padding-bottom ln-u-padding-right ln-u-padding-left
        ln-u-text-align-between`}
    >
      <GridWrapper className='ln-u-font-weight-bold'>
        <GridItem size={{ md: '1/6', lg: '1/8' }}>
          {props.title}
          {props.isFaulty && (
            <ErrorCircle
              size='large'
              className='ln-u-color-red ln-u-margin-left*1/2'
            />
          )}
        </GridItem>
        {props.isFaulty && (
          <>
            <GridItem data-testid='faulty-text' size={{ md: '3/6', lg: '5/8' }}>
              This cabinet has been marked as faulty, DO NOT USE until fixed. To
              report the problem or check on the progress of the work order
              please visit&nbsp;
              <Link
                onClick={() =>
                  (window.location.href =
                    'https://fmassist.sainsburys.co.uk/fmassist_landing')
                }
              >
                FM Assist
              </Link>
              .
            </GridItem>
            <GridItem size={{ md: '2/6', lg: '2/8' }}>
              <OutlinedButton
                className='c-counter-card-fixed-button ln-u-margin-right'
                onClick={() => onChangeFaultyOrFixed()}
                data-testid='cabinet-fixed-button'
              >
                Mark as fixed
              </OutlinedButton>
            </GridItem>
          </>
        )}
      </GridWrapper>
    </div>
  )
  return (
    <>
      {/* if temperature is on 'Ready' status, show the inputboxes.
      If counter is marked as faulty, display error message */}
      {props.tempCheckStatus === TempCheckCounterStatus.Ready && (
        <GridItem
          key={props.counterNumber}
          size={{ default: '1/1' }}
          className='ln-u-hard'
          data-testid={`counter-card-${props.counterNumber}`}
        >
          <Card
            className={`ln-u-hard
              ln-u-margin-bottom*2 ln-u-padding-right*2 ln-u-padding-top*2 ln-u-padding-bottom*2
              c-counter-card-tab-padding`}
            data-testid='cabinet-card'
          >
            {renderCardHeader()}

            {!props.isFaulty && (
              <>
                <div
                  className={`ln-u-padding-top ln-u-padding-bottom ln-u-padding-right ln-u-padding-left
                  ln-u-text-align-center`}
                  data-testid='cabinet-card-body'
                >
                  {temperatures.map((item, index) => (
                    <CounterTempCheckInputCard
                      key={index}
                      counterNumber={props.counterNumber}
                      hasError={
                        validation.errorData.filter(
                          (t) => t.position === item.position,
                        ).length > 0
                      }
                      currentTemp={item}
                      temperatures={temperatures}
                      setTemperatures={setTemperatures}
                      isTurbo={props.isTurboServe}
                      isModal={false}
                      isFirstCheck={props.isFirstCheck}
                      isBluetoothReading={props.isBluetoothReading}
                    />
                  ))}
                </div>

                {!validation.result && (
                  <TemperatureCheckErrorMessage
                    class='ln-u-font-weight-bold ln-u-text-align-left ln-u-padding-left'
                    messageKey={validation.messageKey}
                  />
                )}

                {renderButtons()}
              </>
            )}
          </Card>
        </GridItem>
      )}

      {/* if temperature is on 'Checked' status, just display title and tick icon */}
      {props.tempCheckStatus === TempCheckCounterStatus.Checked && (
        <GridItem
          key={props.counterNumber}
          size={{ default: '1/1' }}
          className='ln-u-hard'
          data-testid='success'
        >
          {props.isFaulty ? (
            <Card
              className={`ln-u-hard
                    ln-u-margin-bottom*2 ln-u-padding-right*2 ln-u-padding-top*2 ln-u-padding-bottom*2
                    c-counter-card-tab-padding`}
            >
              {renderCardHeader()}
            </Card>
          ) : (
            <ActionBanner
              message={props.title}
              icon={<Tick />}
              className='c-common-success-banner'
            />
          )}
        </GridItem>
      )}
    </>
  )
}

export default CounterCard
