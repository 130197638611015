import '../../../scss/common/components/qpig.scss'

import {
  FlagBody,
  FlagComponent,
  FlagWrapper,
  GridItem,
  GridWrapper,
} from '@jsluna/react'
import React from 'react'

import { IAdditionalInformation } from '../../types/IAdditionalInformation'
import { IQpigImages } from '../../types/IQpigImages'
import {
  QpigStepTypes,
  QpigTypes,
  StepTypeToImageStepId,
} from '../../types/IQpigTypes'
import QpigImage from './QpigImage'
import QpigPackagingCodeTable from './QpigPackagingCodeTable'
import QpigProcessStepsTable from './QpigProcessStepsTable'
import { hasTableFormatData } from './QpigUtils'

interface IProps {
  additionalInformation: IAdditionalInformation[]
  pluCodes: string[]
  qpigImages: IQpigImages
}

const QpigProcessSteps = (props: IProps) => {
  const { additionalInformation, pluCodes, qpigImages } = props
  const qpigStepTypes = QpigStepTypes
  let qpigNumber = 1

  const getBulletData = (
    qpig: IAdditionalInformation,
    j: string,
    bulletClassName: string,
  ) => (
    <div key={j}>
      <ul className='ln-u-flush ln-u-margin-bottom*2 ln-u-margin-left*2'>
        <li className={`ln-u-hard ${bulletClassName}`}>
          {qpig.description.substring(
            qpig.description.indexOf(':') + 1,
            qpig.description.length,
          )}
        </li>
      </ul>
    </div>
  )

  const qpigTables = (qpigStepType: string) => (
    <React.Fragment>
      {qpigStepType === QpigTypes.QpigPackaging && (
        <QpigPackagingCodeTable
          additionalInformationList={additionalInformation.filter(
            (q) => q.type === QpigTypes.QpigPackagingCodes,
          )}
          pluCodes={pluCodes}
        />
      )}
      <QpigProcessStepsTable
        additionalInformationList={additionalInformation.filter(
          (q) => q.type === qpigStepType,
        )}
      />
    </React.Fragment>
  )

  const qpigBulletPoints = (qpigStepType: string) => (
    <React.Fragment>
      {additionalInformation.map((qpig: IAdditionalInformation) => {
        const rowId = qpig.description.substring(
          0,
          qpig.description.indexOf(':'),
        )
        if (qpig.type === qpigStepType && !hasTableFormatData([qpig])) {
          const stepsCount = additionalInformation.filter(
            (q) => q.type === qpigStepType,
          ).length
          if (stepsCount > 1) {
            if (
              qpigStepType === QpigTypes.QpigTargetProduct ||
              qpigStepType === QpigTypes.QpigProductShouldBeRejectedIf
            ) {
              return getBulletData(qpig, rowId, '')
            }
            return getBulletData(qpig, rowId, 'ln-u-margin-left*4')
          } else {
            if (
              qpigStepType === QpigTypes.QpigTargetProduct ||
              qpigStepType === QpigTypes.QpigProductShouldBeRejectedIf
            ) {
              return getBulletData(qpig, rowId, '')
            }
            return getBulletData(
              qpig,
              rowId,
              'c-qpig-no-bullet ln-u-margin-left*2',
            )
          }
        }
      })}
    </React.Fragment>
  )

  const stepHasImages = (qpigStepType: string): boolean => {
    if (!qpigImages || !qpigImages.imageFileNames) {
      return false
    }

    const qpigImageStepId = StepTypeToImageStepId[qpigStepType]
    return qpigImages.imageFileNames.some(
      (x) => x.toUpperCase().split('_')[1] === qpigImageStepId.toUpperCase(),
    )
  }

  const getStepImages = (qpigStepType: string): string[] => {
    const qpigImageStepId = StepTypeToImageStepId[qpigStepType]
    return qpigImages.imageFileNames.filter(
      (x) => x.toUpperCase().split('_')[1] === qpigImageStepId.toUpperCase(),
    )
  }

  const getGridSize = (qpigStepType: string): string => {
    if (stepHasImages(qpigStepType)) {
      return '2/3'
    } else {
      return '1/1'
    }
  }

  const getStepHeader = (qpigStepType: string) => {
    if (
      qpigStepType === QpigTypes.QpigProductShouldBeRejectedIf ||
      qpigStepType === QpigTypes.QpigTargetProduct
    ) {
      return (
        <FlagWrapper className='ln-u-margin-bottom'>
          <FlagBody>
            <h4 className='ln-u-flush'>{qpigStepType.split('QPIG - ')}</h4>
          </FlagBody>
        </FlagWrapper>
      )
    }
    return (
      <FlagWrapper className='ln-u-margin-bottom'>
        <FlagComponent>
          <h3 className='ln-u-hard ln-u-color-alpha ln-u-flush c-qpig-circle ln-font'>
            {qpigNumber++}
          </h3>
        </FlagComponent>
        <FlagBody>
          <h4 className='ln-u-margin-left*2 ln-u-flush c-qpig-font-size-24px'>
            {qpigStepType.split('QPIG - ')}
          </h4>
        </FlagBody>
      </FlagWrapper>
    )
  }

  const getQpigSteps = () => {
    if (additionalInformation === null || additionalInformation === undefined) {
      return <div />
    }
    const processSteps = qpigStepTypes.map((qpigStepType, i) => {
      const outerRowId = `${qpigStepType}_outer_${i}`
      if (
        additionalInformation === null ||
        additionalInformation === undefined ||
        !additionalInformation.some((qpig: IAdditionalInformation) =>
          qpig.type.startsWith(qpigStepType),
        )
      ) {
        if (stepHasImages(qpigStepType)) {
          return (
            <div
              key={outerRowId}
              className='ln-u-margin-sides*4 ln-u-margin-top*3'
            >
              {getStepHeader(qpigStepType)}
              <GridWrapper>
                <GridItem
                  size={{ xs: '1/1', sm: '1/3' }}
                  className='ln-u-margin-left*4'
                >
                  <div className='c-qpig-single-image-margin'>
                    {getStepImages(qpigStepType).map((imgFileName: string) => (
                      <QpigImage
                        baseFilePath={qpigImages.baseFilePath}
                        imgFileName={imgFileName}
                        signature={qpigImages.signature}
                      />
                    ))}
                  </div>
                </GridItem>
              </GridWrapper>
            </div>
          )
        } else {
          return
        }
      }
      return (
        <div key={outerRowId} className='ln-u-margin-top*3 ln-u-margin-sides*4'>
          {getStepHeader(qpigStepType)}
          <GridWrapper>
            <GridItem
              size={{ xs: '1/1', sm: getGridSize(qpigStepType) }}
              alignment='top'
            >
              <div className='c-table-responsive c-qpig-table-margin'>
                {qpigBulletPoints(qpigStepType)}
              </div>
            </GridItem>
            {stepHasImages(qpigStepType) && (
              <GridItem
                size={{ xs: '1/1', sm: '1/3' }}
                className='c-qpig-image-margin'
              >
                {getStepImages(qpigStepType).map((imgFileName: string) => (
                  <QpigImage
                    key={imgFileName}
                    baseFilePath={qpigImages.baseFilePath}
                    imgFileName={imgFileName}
                    signature={qpigImages.signature}
                  />
                ))}
              </GridItem>
            )}
          </GridWrapper>

          <div className='c-table-responsive c-qpig-table-margin'>
            {qpigTables(qpigStepType)}
          </div>
        </div>
      )
    })
    return processSteps
  }

  return <div>{getQpigSteps()}</div>
}

export default QpigProcessSteps
