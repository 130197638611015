import { GridItem, GridWrapper } from '@jsluna/react'
import React from 'react'

interface IActionBanner {
  message: string
  icon: JSX.Element
  className: string
  handleIconClick?: () => void
}

const ActionBanner = ({
  message,
  icon,
  className,
  handleIconClick,
}: IActionBanner) => (
  <div className={`c-common-action-banner ${className}`}>
    <GridWrapper className='ln-u-display-flex ln-u-align-items-center'>
      <GridItem
        size={{ default: '9/10' }}
        className={`${className} c-common-banner-text`}
      >
        {message}
      </GridItem>
      <GridItem
        size={{ default: '1/10' }}
        className={`${className} c-common-banner-icon`}
      >
        <a onClick={handleIconClick}>{icon}</a>
      </GridItem>
    </GridWrapper>
  </div>
)

export default ActionBanner
