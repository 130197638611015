import '../../../scss/common/components/qpig.scss'

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import React from 'react'

import { IAdditionalInformation } from '../../types/IAdditionalInformation'
import { QpigTypes } from '../../types/IQpigTypes'
import {
  getColumnText,
  getRowFields,
  getRowIdentifier,
  getValueFromField,
} from './QpigUtils'

interface IProps {
  additionalInformation: IAdditionalInformation[]
}

interface IIngredientColumnType {
  displayName: string
  evolveName: string[]
  colSpan: number
  shortDisplayName: string
}

const QpigIngredients = (props: IProps) => {
  const { additionalInformation } = props

  const caseInsensitiveCompare = (a: string, b: string): boolean =>
    a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
      ? true
      : false

  const caseInsensitiveCompareList = (a: string[], b: string): boolean =>
    a.some((c) =>
      c.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
        ? true
        : false,
    )

  const findColumnByEvolveName = (
    allColumnsArray: IIngredientColumnType[],
    filterProperty: string,
  ): IIngredientColumnType => {
    const foundColumn = allColumnsArray.find((column: IIngredientColumnType) =>
      column.evolveName.some((evolveName) =>
        caseInsensitiveCompare(evolveName, filterProperty),
      ),
    )

    return foundColumn!
  }

  const setAllColumns = (): IIngredientColumnType[] => {
    if (additionalInformation == null) {
      return []
    }

    const allColumnsArray: IIngredientColumnType[] = [
      {
        colSpan: 0,
        displayName: 'Ingredients',
        evolveName: ['Ingredients', 'Ingredient'],
        shortDisplayName: 'Ingredient',
      },
      {
        colSpan: 0,
        displayName: 'Orderable SKU',
        evolveName: ['Orderable SKU'],
        shortDisplayName: 'SKU',
      },
      {
        colSpan: 0,
        displayName: 'Quantity',
        evolveName: ['QTY'],
        shortDisplayName: 'QTY',
      },
    ]

    const ingredientQpigs = additionalInformation.filter(
      (additionalInfo) => additionalInfo.type === QpigTypes.QpigIngredient,
    )

    ingredientQpigs.forEach((ingredientQpig) => {
      const ingredientRow = getColumnText(ingredientQpig.description)
      const columns = ingredientRow.split(',')

      const dictionary: { [id: string]: number } = {}

      columns.forEach((column) => {
        const columnIdentifier = column
          .substring(0, column.indexOf(':'))
          .trim()
          .toUpperCase()
        if (dictionary[columnIdentifier] !== undefined) {
          dictionary[columnIdentifier]++
        } else {
          dictionary[columnIdentifier] = 1
        }
      })

      allColumnsArray.forEach((c) => {
        c.evolveName.forEach((evolveName) => {
          if (dictionary[evolveName.toUpperCase()] !== undefined) {
            c.colSpan = dictionary[evolveName.toUpperCase()]
          }
        })
      })
    })

    if (
      additionalInformation.some(
        (additionalInfo: IAdditionalInformation) =>
          additionalInfo.type === QpigTypes.QpigGenesisPlantAdjustment,
      )
    ) {
      const qtyColumn = findColumnByEvolveName(allColumnsArray, 'QTY')
      if (qtyColumn !== undefined) {
        allColumnsArray.push({
          colSpan: qtyColumn.colSpan,
          displayName: 'Genesis Plant Quantity',
          evolveName: ['Adjustment'],
          shortDisplayName: 'Genesis QTY',
        })
      }
    }

    return allColumnsArray
  }

  const allColumns: IIngredientColumnType[] = setAllColumns()

  const getAllColumns = () => {
    if (
      additionalInformation === null ||
      additionalInformation === undefined ||
      !additionalInformation.some(
        (additionalInfo: IAdditionalInformation) =>
          additionalInfo.type === QpigTypes.QpigIngredient,
      )
    ) {
      return (
        <tbody>
          <tr>
            <td className='ln-u-text-align-center'>No Ingredients Found</td>
          </tr>
        </tbody>
      )
    }

    return (
      <TableHeader>
        <TableRow data-testid='ingredient-header-row'>
          {allColumns
            .filter((c) => c.colSpan > 0)
            .map((columnName) => (
              <TableCell
                key={columnName.evolveName[0]}
                className='ln-u-font-weight-bold'
                colSpan={columnName.colSpan}
              >
                {columnName.displayName}
              </TableCell>
            ))}
        </TableRow>
      </TableHeader>
    )
  }

  const getNumberOfColumns = () =>
    allColumns
      .filter(
        (x) => caseInsensitiveCompareList(x.evolveName, 'Adjustment') === false,
      )
      .map((c) => c.colSpan)
      .reduce((total, c) => total + c)

  const getShortDisplayName = (columnName: string): string => {
    const column = findColumnByEvolveName(allColumns, columnName)
    return column ? column.shortDisplayName : ''
  }

  const getColumnNameFromCell = (cellText: string): string =>
    cellText.substring(0, cellText.indexOf(':')).trim()

  const getIngredientRows = () => {
    if (
      additionalInformation === null ||
      additionalInformation === undefined ||
      !additionalInformation.some(
        (additionalInfo: IAdditionalInformation) =>
          additionalInfo.type === QpigTypes.QpigIngredient,
      )
    ) {
      return <tr />
    }

    return additionalInformation
      .filter(
        (additionalInfo) => additionalInfo.type === QpigTypes.QpigIngredient,
      )
      .sort((a: IAdditionalInformation, b: IAdditionalInformation) =>
        getRowIdentifier(a) < getRowIdentifier(b) ? -1 : 1,
      )
      .map((additionalInfo) => {
        const rowName = getRowIdentifier(additionalInfo)
        const updatedDescription = getColumnText(additionalInfo.description)
        const columns = getRowFields(updatedDescription)
        const numberOfColumns = getNumberOfColumns()
        if (columns.length !== numberOfColumns) {
          return (
            <TableRow data-testid='ingredient-row' key={rowName}>
              <TableCell colSpan={numberOfColumns}>
                {updatedDescription}
              </TableCell>
            </TableRow>
          )
        }

        return (
          <TableRow data-testid='ingredient-row' key={rowName}>
            {columns.map((col, i) => (
              <TableCell
                label={getShortDisplayName(getColumnNameFromCell(col))}
                key={i}
              >
                {getValueFromField(col)}
              </TableCell>
            ))}
            {getGenesisCells(columns)}
          </TableRow>
        )
      })
  }

  const getGenesisCells = (columns: string[]) => {
    const qtyColumn = findColumnByEvolveName(allColumns, 'QTY')
    if (
      allColumns.some((x) =>
        caseInsensitiveCompareList(x.evolveName, 'Adjustment'),
      ) &&
      qtyColumn !== undefined &&
      qtyColumn.colSpan > 0
    ) {
      let adjustData: string[] = []
      if (getValueFromField(columns[0]).toUpperCase().indexOf('WATER') >= 0) {
        const relevantRow = additionalInformation.find(
          (x) =>
            x.type === QpigTypes.QpigGenesisPlantAdjustment &&
            x.description.toUpperCase().indexOf('WATER') >= 0,
        )
        if (relevantRow !== undefined) {
          adjustData = relevantRow.description
            .substring(
              relevantRow.description.indexOf(',') + 1,
              relevantRow.description.length,
            )
            .split(',')
        }
      }

      if (getValueFromField(columns[0]).toUpperCase().indexOf('FLOUR') >= 0) {
        const relevantRow = additionalInformation.find(
          (x) =>
            x.type === QpigTypes.QpigGenesisPlantAdjustment &&
            x.description.toUpperCase().indexOf('FLOUR') >= 0,
        )
        if (relevantRow !== undefined) {
          adjustData = relevantRow.description
            .substring(
              relevantRow.description.indexOf(',') + 1,
              relevantRow.description.length,
            )
            .split(',')
        }
      }

      if (getValueFromField(columns[0]).toUpperCase().indexOf('YEAST') >= 0) {
        const relevantRow = additionalInformation.find(
          (x) =>
            x.type === QpigTypes.QpigGenesisPlantAdjustment &&
            x.description.toUpperCase().indexOf('YEAST') >= 0,
        )
        if (relevantRow !== undefined) {
          adjustData = relevantRow.description
            .substring(
              relevantRow.description.indexOf(',') + 1,
              relevantRow.description.length,
            )
            .split(',')
        }
      }

      if (adjustData.length === 0) {
        const quantityColumns = columns.filter(
          (col) => getColumnNameFromCell(col).toUpperCase() === 'QTY',
        )
        adjustData = quantityColumns.map((col) => getValueFromField(col))
      }

      const res = []
      if (adjustData !== undefined && adjustData.length > 0) {
        for (let i = 0; i < qtyColumn.colSpan; i++) {
          if (adjustData[i] === undefined) {
            res.push(
              <TableCell
                label='Genesis QTY'
                key={`Genesis${i}${qtyColumn.colSpan}`}
              >
                {adjustData[0].trim()}
              </TableCell>,
            )
          } else {
            res.push(
              <TableCell
                label='Genesis QTY'
                key={`Genesis${i}${qtyColumn.colSpan}`}
              >
                {adjustData[i].trim()}
              </TableCell>,
            )
          }
        }
      }

      return res
    }
  }

  const getIngredientTotals = () => {
    if (
      additionalInformation == null ||
      additionalInformation === undefined ||
      !additionalInformation.some(
        (additionalInfo: IAdditionalInformation) =>
          additionalInfo.type === QpigTypes.QpigIngredientSummary,
      )
    ) {
      return
    }

    return additionalInformation
      .filter(
        (additionalInfo) =>
          additionalInfo.type === QpigTypes.QpigIngredientSummary,
      )
      .map((additionalInfo) => {
        const rows = getColumnText(additionalInfo.description).split(',')
        return rows.map((rowText, rowId) => {
          const rowTitle = rowText.substring(0, rowText.indexOf(':')).trim()
          const rowData = rowText.substring(
            rowText.indexOf(':') + 1,
            rowText.length,
          )

          return (
            <TableRow key={rowId} className='ln-u-font-weight-bold'>
              <TableCell key={`Title${rowId}`}>{rowTitle}</TableCell>
              <TableCell key={`Space${rowId}`} />
              {getTotalQuantitiesColumns(rowData, rowTitle)}
            </TableRow>
          )
        })
      })
  }

  const getIngredientTotalsMobile = () => {
    if (
      additionalInformation == null ||
      additionalInformation === undefined ||
      !additionalInformation.some(
        (additionalInfo: IAdditionalInformation) =>
          additionalInfo.type === QpigTypes.QpigIngredient,
      )
    ) {
      return
    }

    return additionalInformation
      .filter(
        (additionalInfo) =>
          additionalInfo.type === QpigTypes.QpigIngredientSummary,
      )
      .map((additionalInfo) => {
        const rows = getColumnText(additionalInfo.description).split(',')
        return rows.map((rowText, rowId) => {
          const rowTitle = rowText.substring(0, rowText.indexOf(':')).trim()
          const rowData = rowText
            .substring(rowText.indexOf(':') + 1, rowText.length)
            .trim()

          return (
            <TableRow key={rowId} className='ln-u-font-weight-bold'>
              <TableCell key={`Title${rowId}`}>{rowTitle}</TableCell>
              <TableCell key={`Space${rowId}`} />
              <TableCell key={`Data${rowId}`}>{rowData}</TableCell>
            </TableRow>
          )
        })
      })
  }

  const getTotalQuantitiesColumns = (rowData: string, rowTitle: string) => {
    const splitData = rowData.split('/')

    const qtyColumn = findColumnByEvolveName(allColumns, 'QTY')
    const res = []

    if (qtyColumn !== undefined) {
      for (let i = 0; i < qtyColumn.colSpan; i++) {
        res.push(
          <TableCell key={`Total${i}`}>
            {getTotalsData(splitData, i)}
          </TableCell>,
        )
      }
    }

    if (
      allColumns.some(
        (x) => x.evolveName.includes('Adjustment') && qtyColumn !== undefined,
      )
    ) {
      let adjustData: string[] = []
      if (rowTitle.toUpperCase().indexOf('YIELD') >= 0) {
        const relevantRow = additionalInformation.find(
          (x) =>
            x.type === QpigTypes.QpigGenesisPlantAdjustment &&
            x.description.toUpperCase().indexOf('YIELD') >= 0,
        )
        if (relevantRow !== undefined) {
          adjustData = relevantRow.description
            .substring(
              relevantRow.description.indexOf(',') + 1,
              relevantRow.description.length,
            )
            .split(',')
        }
      } else if (rowTitle.toUpperCase().indexOf('WEIGHT') >= 0) {
        const relevantRow = additionalInformation.find(
          (x) =>
            x.type === QpigTypes.QpigGenesisPlantAdjustment &&
            x.description.toUpperCase().indexOf('WEIGHT') >= 0,
        )
        if (relevantRow !== undefined) {
          adjustData = relevantRow.description
            .substring(
              relevantRow.description.indexOf(',') + 1,
              relevantRow.description.length,
            )
            .split(',')
        }
      }

      for (let i = 0; i < qtyColumn.colSpan; i++) {
        if (adjustData[i] === undefined && adjustData[0] === undefined) {
          res.push(
            <TableCell key={`Total${i}${qtyColumn.colSpan}`}>
              {getTotalsData(splitData, i)}
            </TableCell>,
          )
        } else if (adjustData[i] === undefined && adjustData[0] !== undefined) {
          res.push(
            <TableCell key={`Total${i}${qtyColumn.colSpan}`}>
              {adjustData[0].trim()}
            </TableCell>,
          )
        } else {
          res.push(
            <TableCell key={`Total${i}${qtyColumn.colSpan}`}>
              {adjustData[i].trim()}
            </TableCell>,
          )
        }
      }
    }

    return res
  }

  const getTotalsData = (rowData: string[], columnIndex: number): string => {
    let data: string
    if (rowData.length <= columnIndex) {
      data = rowData[0]
    } else {
      data = rowData[columnIndex]
    }

    const splitedData = data.split(':')
    if (splitedData.length === 1) {
      return splitedData[0].trim()
    }
    return splitedData[1].trim()
  }

  const getTitle = () => {
    if (
      additionalInformation !== undefined &&
      additionalInformation !== null &&
      additionalInformation.length > 0
    ) {
      return <h4> Ingredients </h4>
    }
  }

  return (
    <div className='c-table-responsive'>
      <div className='c-table-responsive__mobile'>
        {getTitle()}
        <TableContainer className='c-qpig-border-line' responsive>
          {getAllColumns()}
          <TableBody>
            {getIngredientRows()}
            {getIngredientTotalsMobile()}
          </TableBody>
        </TableContainer>
      </div>

      <div className='c-table-responsive__desktop'>
        {getTitle()}
        <TableContainer className='c-qpig-border-line'>
          {getAllColumns()}
          <TableBody>
            {getIngredientRows()}
            {getIngredientTotals()}
          </TableBody>
        </TableContainer>
      </div>
    </div>
  )
}

export default QpigIngredients
