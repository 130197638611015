import { IStorePlanners } from '../common/types/IStorePanners'

const getLocalStorePlanners = (): IStorePlanners => {
  const storePlanners = localStorage.getItem('STORE_PLANNERS')

  const result: IStorePlanners =
    storePlanners !== null
      ? (JSON.parse(storePlanners) as IStorePlanners)
      : { hasBakery: false, hasPizza: false, hasHotFood: false, count: 0 }
  result.count =
    (result.hasBakery ? 1 : 0) +
    (result.hasPizza ? 1 : 0) +
    (result.hasHotFood ? 1 : 0)

  return result
}

const setLocalStorePlanners = (planner: IStorePlanners) =>
  localStorage.setItem('STORE_PLANNERS', JSON.stringify(planner))

const clearLocalStorePlanners = () => {
  localStorage.removeItem('USER_STORE')
  localStorage.removeItem('STORE_PLANNERS')
}

export { clearLocalStorePlanners, getLocalStorePlanners, setLocalStorePlanners }
