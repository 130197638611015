import { ErrorCircle } from '@jsluna/icons'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  FlagComponent,
  FlagWrapper,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
} from '@jsluna/react'

import { AlertMessageKey } from '../../enums/AlertMessageKey'
import { IAlertModalProps } from '../../types/IAlertModalProps'
import {
  CounterTemperatureAlertMessages,
  ICounterTemperatureAlertMessage,
} from '../../utils/TemperatureAlertMessages'

const CounterAlertModal = (props: IAlertModalProps) => {
  const message: ICounterTemperatureAlertMessage =
    CounterTemperatureAlertMessages.get(
      props.alertKey || AlertMessageKey.NoMessageFound,
    ) || {
      instruction: '',
      title: '',
    }

  const getMessageContent = () => (
    <>
      {message && (
        <>
          <ModalHeading element='h4'>
            <FlagWrapper>
              <FlagComponent className='ln-u-margin-top*2 ln-u-margin-right'>
                <ErrorCircle className='c-form-alert-icon-red ln-u-display-6-fixed' />
              </FlagComponent>
              <FlagComponent
                data-testid='modalTitle'
                className='c-counter-alert-title-font ln-u-margin-top*2'
              >
                {message.title.split('\n').map((line, i) => (
                  <div key={i}>
                    {line}
                    <br />
                  </div>
                ))}
              </FlagComponent>
            </FlagWrapper>
          </ModalHeading>

          <div data-testid='modalDescription'>{message.instruction}</div>
        </>
      )}
    </>
  )

  return (
    <>
      <Modal
        small
        handleClose={() => props.handleClose()}
        open={props.isOpen}
        headingId='info-modal'
      >
        {getMessageContent()}

        <ButtonGroupWrapper>
          <ButtonGroupSecondary className='u-full-width'>
            <GridWrapper>
              <GridItem
                className='c-pizza-modal-button-padding'
                size={{
                  default: '1/1',
                  xs: '1/2',
                  sm: '1/2',
                  md: '1/2',
                  lg: '1/2',
                }}
              >
                <FilledButton
                  className='c-pizza-modal-button c-submit-modal-button u-full-width'
                  onClick={async () => await props.handleAction()}
                >
                  {message.actionButtonText}
                </FilledButton>
              </GridItem>
            </GridWrapper>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </Modal>
    </>
  )
}

export default CounterAlertModal
