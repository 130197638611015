import { PlannerName } from '../enums/PlannerNameEnum'
import { IQpigImages } from '../types/IQpigImages'
import { IQpigImagesQueryResult } from '../types/IQpigImagesQueryResult'
import { ApiClient } from './apiClient'

export const getQpigImages = async (
  apiClient: ApiClient,
  skuId: number,
): Promise<IQpigImages> =>
  (
    await apiClient.get<IQpigImagesQueryResult>(
      PlannerName.None,
      `skus/qpigimages/${skuId}`,
    )
  ).qpigImages
