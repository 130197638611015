const parseErrorStack = (error: Error) => {
  // Remove the first line of error message
  const stackLines = error?.stack?.split('\n').slice(1)

  const stackFrames = stackLines
    ?.map((line) => {
      const parts = line.trim().match(/^(.*?)@(.*?):(\d+):(\d+)$/)
      if (parts && parts.length === 5) {
        return {
          functionName: parts[1] || null,
          fileName: parts[2] || null,
          lineNumber: parseInt(parts[3], 10) || null,
          columnNumber: parseInt(parts[4], 10) || null,
        }
      } else {
        // Unable to parse the stack line
        return null
      }
    })
    .filter((frame: any) => frame !== null)
  return stackFrames
}

export default parseErrorStack
