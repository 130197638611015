import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IBatchTemperaturesResponse } from '../types/IBatchTemperaturesResponse'
import { ICounterTemperaturesResponse } from '../types/ICounterTemperaturesResponse'

const getTemperatureChecks = async (
  apiClient: ApiClient,
  storeCode: number,
  batchDate: Date,
): Promise<IBatchTemperaturesResponse> =>
  await apiClient.get<IBatchTemperaturesResponse>(
    PlannerName.HotFood,
    `report/${storeCode}/${batchDate.toDateString()}`,
  )

const getCounterTemperatures = async (
  apiClient: ApiClient,
  storeCode: number,
  batchDate: Date,
): Promise<ICounterTemperaturesResponse> =>
  await apiClient.get<ICounterTemperaturesResponse>(
    PlannerName.HotFood,
    `report/counters/${storeCode}/${batchDate.toDateString()}`,
  )

export { getCounterTemperatures, getTemperatureChecks }
