import { GridItem, GridWrapper } from '@jsluna/react'
import React from 'react'

interface IBanner {
  message: string
  icon: JSX.Element
  className: string
  messageObject?: JSX.Element
}

const Banner = ({ message, icon, className, messageObject }: IBanner) => (
  <div className={`c-common-banner ${className}`}>
    <div className='u-display-lg'>
      <div className='ln-u-display-flex ln-u-align-items-center'>
        <>
          {icon}
          <span className='c-common-banner-text'>
            {message.length > 0 && message}
            {messageObject !== undefined && messageObject}
          </span>
        </>
      </div>
    </div>

    <GridWrapper className='u-display-sm'>
      <GridItem size={{ default: '1/10' }}>{icon}</GridItem>
      <GridItem size={{ default: '9/10' }} className='c-common-banner-text'>
        {message.length > 0 && message}
        {messageObject !== undefined && messageObject}
      </GridItem>
    </GridWrapper>
  </div>
)

export default Banner
