import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import ICounterCheck from '../types/ICounterCheck'
import { ICreateCounterTemperaturesRequest } from '../types/ICreateCounterTemperaturesRequest'
import { ICreateCounterTemperaturesResponse } from '../types/ICreateCounterTemperaturesResponse'

// Get counters based on the grouped counter number as a unique counter
const getCounters = async (
  apiClient: ApiClient,
  storeCode: number,
  requestDate: Date,
): Promise<ICounterCheck[]> =>
  await apiClient.get<ICounterCheck[]>(
    PlannerName.HotFood,
    `counters/${storeCode}/${requestDate.toDateString()}`,
  )

// Get all counters with temperature data collection with position information
const getCounterTempChecks = async (
  apiClient: ApiClient,
  storeCode: number,
  requestDate: Date,
): Promise<ICounterCheck[]> =>
  await apiClient.get<ICounterCheck[]>(
    PlannerName.HotFood,
    `counters/temperatureChecks/${storeCode}/${requestDate.toDateString()}`,
  )

// Create a new counter temperature
const createCounterTemperatures = async (
  apiClient: ApiClient,
  request: ICreateCounterTemperaturesRequest,
): Promise<ICreateCounterTemperaturesResponse> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(
    PlannerName.HotFood,
    'counters/createCounterTemperatures',
    body,
  )
  return (await result.json()) as ICreateCounterTemperaturesResponse
}

const createCabinetStatus = async (
  apiClient: ApiClient,
  request: ICounterCheck,
): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(
    PlannerName.HotFood,
    'counters/status',
    body,
  )
  return (await result.json()) as IResult
}

export {
  createCabinetStatus,
  createCounterTemperatures,
  getCounters,
  getCounterTempChecks,
}
