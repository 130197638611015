import {
  GridItem,
  GridWrapper,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import React from 'react'

interface IProps {
  rawShelfLifeComments: string[]
}

const PossibleRowTitlesOrdered: string[] = [
  'Display Life',
  'Customer Life',
  'Total Life',
]

const QpigShelfLife = (props: IProps) => {
  let error = ''
  let columnTitles: string[] = ['']
  const rowTitles: string[] = []
  const rowsByColumn: string[][] = []
  const columnsByRow: string[][] = []

  if (!props.rawShelfLifeComments) {
    return <></>
  }

  const isKnownHeaderWithDash = (text: string) => {
    const formatText = text.trim().toLowerCase().toString()
    if (formatText === 'un-sliced loaf' || formatText === 'un-sliced') {
      return true
    }
    return false
  }

  const getSeparatorIndex = (text: string): number => {
    let separatorIndex = text.indexOf(':')
    if (separatorIndex === -1 && isKnownHeaderWithDash(text) === false) {
      separatorIndex = text.indexOf('-')
    }

    if (separatorIndex === -1) {
      separatorIndex = text.indexOf('=')
    }

    return separatorIndex
  }

  const getHeader = () => (
    <>
      <h4 data-testid='shelf-life-header'>Shelf Life</h4>
      <p>
        Ensure that the product is merchandised correctly refer to the planogram
      </p>
    </>
  )

  props.rawShelfLifeComments.forEach((column) => {
    if (!column) {
      return
    }

    const rows: string[] = []

    const columnRows = column.split('\n')

    for (const row of columnRows) {
      if (row === '') {
        continue
      }
      const separatorIndex = getSeparatorIndex(row)

      if (separatorIndex === -1) {
        columnTitles.push(row.trim())
        continue
      }

      const rowTitle = row.substring(0, separatorIndex).trim()

      if (
        !PossibleRowTitlesOrdered.some(
          (title) => title.toUpperCase() === rowTitle.toUpperCase(),
        )
      ) {
        continue
      }

      if (
        !rowTitles.some(
          (title) =>
            title.toUpperCase() === rowTitle.toUpperCase().toUpperCase(),
        )
      ) {
        rowTitles.push(rowTitle)
      }

      rows.push(row)
    }

    rowsByColumn.push(rows.sort())
  })

  if (rowsByColumn.some((x) => x.length !== rowsByColumn[0].length)) {
    error = 'Not every column has the same number of rows'
  }

  if (columnTitles.length === 1) {
    columnTitles = []
  }

  if (error !== '') {
    return (
      <>
        <h4>Shelf Life - errors</h4>
        <p>{error}</p>
        {props.rawShelfLifeComments.map((rawShelfLifeComment) => (
          <p>{rawShelfLifeComment}</p>
        ))}
      </>
    )
  }

  let rowIndex = -1
  PossibleRowTitlesOrdered.forEach((PossibleShelfLifeRowTitle) => {
    if (
      rowTitles.some(
        (title) =>
          title.toUpperCase() === PossibleShelfLifeRowTitle.toUpperCase(),
      )
    ) {
      rowIndex++
      columnsByRow.push([PossibleShelfLifeRowTitle])
      rowsByColumn.forEach((columnRows) => {
        columnRows.forEach((columnRow) => {
          const separatorIndex = getSeparatorIndex(columnRow)
          if (
            columnRow.substring(0, separatorIndex).toUpperCase().trim() ===
            PossibleShelfLifeRowTitle.toUpperCase()
          ) {
            const rowValue = columnRow
              .substring(separatorIndex + 1, columnRow.length)
              .trim()
            columnsByRow[rowIndex].push(rowValue)
          }
        })
      })
    }
  })

  const getQpigTable = (isResponsive: boolean) => {
    const tableSize = () => {
      if (isResponsive) {
        return '1/1'
      }

      switch (columnTitles.length) {
        case 0:
          return { sm: '1/2', md: '1/3', lg: '1/3' }
        default:
          return '2/3'
      }
    }

    return (
      <div className='ln-u-margin-top'>
        <GridWrapper>
          <GridItem size={tableSize()}>
            <TableContainer responsive={isResponsive}>
              <TableHeader>
                <TableRow
                  data-testid={isResponsive ? 'shelf-life-header-row' : ''}
                >
                  {columnTitles.map((columnTitle, colNumber) => (
                    <TableCell key={colNumber}>{columnTitle}</TableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {columnsByRow.map((row, rowNumber) => (
                  <TableRow
                    data-testid={isResponsive ? 'shelf-life-row' : ''}
                    key={rowNumber}
                  >
                    {row.map((cell, cellNumber) => (
                      <TableCell
                        label={columnTitles[cellNumber]}
                        key={`${rowNumber}_${cellNumber}`}
                      >
                        {cellNumber === 0 ? <b>{cell}</b> : <>{cell}</>}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </GridItem>
        </GridWrapper>
      </div>
    )
  }

  return (
    <div className='c-table-responsive'>
      <div className='c-table-responsive__mobile c-qpig-table-margin ln-u-margin-sides*4'>
        {getHeader()}
        {getQpigTable(true)}
      </div>
      <div className='c-table-responsive__desktop c-qpig-table-margin'>
        {getHeader()}
        {getQpigTable(false)}
      </div>
    </div>
  )
}

export default QpigShelfLife
