import {
  Card,
  GridItem,
  GridWrapper,
  OutlinedButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TabLink,
  Tabs,
  TextButton,
} from '@jsluna/react'
import { StarFilled } from '@sainsburys-tech/icons'
import moment from 'moment'
import React, { useState } from 'react'

import AppTooltip from '../../../common/components/Tooltip/AppTooltip'
import { IDailyIngredientQuantity } from '../../../common/types/IDailyIngredientQuantity'
import { IIngredient } from '../../../common/types/IIngredient'
import { roundUpToTwoDecimalPlace } from '../../../common/utils/roundUpToTwoDecimalPlace'
import { ISubCategory } from '../../types/ISubCategory'

interface IProps {
  handleIngredientChecked: (ingredientId: number) => void
  handleDeselectAll: () => void
  handleSelectAll: (subCategoryId: number) => void
  ingredients: IIngredient[]
  initialTab: number
  subCategories: ISubCategory[]
}

const IngredientTable = (props: IProps) => {
  const tabs = props.subCategories.map((subcat) => ({
    name: subcat.subCategoryName,
    key: subcat.subCategoryId,
  }))

  const [currentTab, setCurrentTab] = useState(props.initialTab)
  const totalCasesTooltip = (
    <AppTooltip
      message={
        "This is the total number of cases you'll need for each SKU for the dates shown."
      }
    />
  )
  const requiredSkusTooltip = (
    <AppTooltip
      message={
        'Selected SKUs can be printed to support with stock checks before ordering.'
      }
    />
  )
  const date = (days: number) => moment().add(days, 'days').format('ddd DD/MM')

  const ingredientTitle = (ingredient: IIngredient) => (
    <>
      (<span className='ln-u-font-weight-bold'>{ingredient.ingredientId}</span>){' '}
      {ingredient.ingredientName}
      {ingredient.isCredibleProduct === true && (
        <StarFilled
          height='25px'
          fill='black'
          className='ln-u-margin-left c-table__icon'
        />
      )}
    </>
  )

  const getDailyQuantities = (
    dailyIngredient: IDailyIngredientQuantity[],
    totalCaseUnits: number,
  ) => {
    const dailyQuantities = new Array<number | string>(7).fill('TBC')
    dailyIngredient.map(
      (dailyQuantity: IDailyIngredientQuantity, i: number) => {
        dailyQuantities[i] = roundUpToTwoDecimalPlace(
          dailyQuantity.quantity / totalCaseUnits,
        )
      },
    )
    return dailyQuantities
  }

  const total = (
    dailyIngredients: IDailyIngredientQuantity[],
    totalCaseUnits: number,
  ) => {
    let res = 0
    dailyIngredients.map(
      (dailyQuantity) =>
        (res += parseFloat(
          roundUpToTwoDecimalPlace(dailyQuantity.quantity / totalCaseUnits),
        )),
    )
    return roundUpToTwoDecimalPlace(res)
  }

  const dayAndQuantity = (dailyQuantity: any[], num: number) => (
    <>
      <GridItem
        size={'1/2'}
        className='ln-u-font-weight-bold ln-u-margin-bottom'
      >
        {date(num)}
      </GridItem>
      <GridItem size={'1/2'} className='ln-u-text-align-center'>
        {dailyQuantity[num]}
      </GridItem>
    </>
  )

  const ingredientCard = () =>
    props.ingredients.map((ingredient: IIngredient, key: number) => {
      const dailyQuantity = getDailyQuantities(
        ingredient.dailyIngredients,
        ingredient.totalCaseUnits,
      )
      return (
        <div
          className='c-pizza-card-container c-pizza-card-row c-common-hide-in-print'
          key={key}
        >
          <div className='ln-u-soft ln-u-font-weight-bold'>
            {ingredientTitle(ingredient)}
          </div>
          <GridItem
            size={'1/2'}
            className='ln-u-font-weight-bold ln-u-margin-bottom'
          >
            {moment().format('ddd DD/MM')}
          </GridItem>
          <GridItem size={'1/2'} className='ln-u-text-align-center'>
            {dailyQuantity[0]}
          </GridItem>
          {dayAndQuantity(dailyQuantity, 1)}
          {dayAndQuantity(dailyQuantity, 2)}
          {dayAndQuantity(dailyQuantity, 3)}
          {dayAndQuantity(dailyQuantity, 4)}
          {dayAndQuantity(dailyQuantity, 5)}
          {dayAndQuantity(dailyQuantity, 6)}
          <GridItem
            size={'1/2'}
            className='ln-u-font-weight-bold ln-u-soft-bottom'
          >
            Total cases
          </GridItem>
          <GridItem size={'1/2'} className='ln-u-text-align-center'>
            {total(ingredient.dailyIngredients, ingredient.totalCaseUnits)}
          </GridItem>
        </div>
      )
    })

  const getIngredientsTable = (ingredients: IIngredient[]) =>
    ingredients.map((ingredient, key) => {
      const dailyQuantity = getDailyQuantities(
        ingredient.dailyIngredients,
        ingredient.totalCaseUnits,
      )
      return (
        <TableRow key={key}>
          <TableCell
            className='c-bakery-ingredient-sku'
            data-testid='ingredientTitle'
          >
            {ingredientTitle(ingredient)}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty1'
          >
            {dailyQuantity[0]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty2'
          >
            {dailyQuantity[1]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty3'
          >
            {dailyQuantity[2]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty4'
          >
            {dailyQuantity[3]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty5'
          >
            {dailyQuantity[4]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty6'
          >
            {dailyQuantity[5]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-quantity'
            data-testid='qty7'
          >
            {dailyQuantity[6]}
          </TableCell>
          <TableCell
            className='c-bakery-ingredient-total-cases'
            data-testid='ingredientTotal'
          >
            <span className='ln-u-font-weight-bold'>
              {total(ingredient.dailyIngredients, ingredient.totalCaseUnits)}
            </span>
          </TableCell>
          <TableCell className='c-bakery-ingredient-required-skus c-common-hide-in-print'>
            <div className='ln-c-form-option--checkbox'>
              <input
                className='ln-c-form-option__input'
                type='checkbox'
                id={ingredient.ingredientId.toString()}
                name={ingredient.ingredientId.toString()}
                checked={ingredient.checked === true}
                onChange={() =>
                  props.handleIngredientChecked(ingredient.ingredientId)
                }
              />
              <label
                className='ln-c-form-option__label u-no-right-padding-sm'
                htmlFor={ingredient.ingredientId.toString()}
              />
            </div>
          </TableCell>
        </TableRow>
      )
    })

  const ingredientTable = (ingredients: IIngredient[]) => (
    <>
      <TableContainer fixed>
        <TableHeader>
          <TableRow>
            <TableCell className='c-bakery-ingredient-sku'>
              SKU/units per case
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate1'
            >
              {moment().format('ddd DD/MM')}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate2'
            >
              {date(1)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate3'
            >
              {date(2)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate4'
            >
              {date(3)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate5'
            >
              {date(4)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate6'
            >
              {date(5)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-quantity'
              data-testid='ingDate7'
            >
              {date(6)}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-total-cases'
              data-testid='totalCases'
            >
              Total cases{totalCasesTooltip}
            </TableCell>
            <TableCell
              className='c-bakery-ingredient-required-skus c-common-hide-in-print'
              data-testid='requiredSku'
            >
              Required SKUs{requiredSkusTooltip}
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>{getIngredientsTable(ingredients)}</TableBody>
      </TableContainer>
      <GridItem
        size={'1'}
        className='ln-u-margin-top ln-u-margin-bottom ln-u-border-bottom c-common-hide-in-print'
      >
        <TextButton
          className='c-bakery-ingredient-select-all'
          onClick={() => props.handleSelectAll(currentTab)}
        >
          Select all
        </TextButton>
      </GridItem>
      <div className='ln-u-font-weight-bold ln-u-margin-top ln-u-margin-bottom c-common-hide-in-print'>
        Print checked items
      </div>
      <div className='c-common-hide-in-print'>
        <OutlinedButton onClick={() => window.print()}>Print</OutlinedButton>
      </div>
    </>
  )

  const handleTabChanged = (e: number) => {
    // if (e !== currentTab) {
    //   props.handleDeselectAll()
    // }
    setCurrentTab(e)
  }

  const displayTabs = () => (
    <Tabs>
      {tabs.map((tab) => (
        <TabLink
          key={tab.key}
          onClick={() => handleTabChanged(tab.key)}
          active={currentTab === tab.key}
        >
          {tab.name}
        </TabLink>
      ))}
    </Tabs>
  )

  return (
    <>
      <Card className='c-common-hide-in-print'>
        <GridWrapper>
          <GridItem
            size={'1/1'}
            className='ln-u-hard-bottom c-common-hide-in-print'
          >
            {displayTabs()}
          </GridItem>
          <GridItem size={'1/1'}>
            {ingredientTable(
              props.ingredients.filter((x) => x.subCategoryId === currentTab),
            )}{' '}
            {ingredientCard()}
          </GridItem>
        </GridWrapper>
      </Card>
      <GridItem className='c-common-only-visible-in-print' size={'1/1'}>
        {ingredientTable(props.ingredients.filter((x) => x.checked === true))}
      </GridItem>
    </>
  )
}

export default IngredientTable
