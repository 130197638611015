import { Link } from '@jsluna/react'
import { StarFilled } from '@sainsburys-tech/icons'
import React from 'react'

import { setCurrentPlanner } from '../../../utils/localStore'
import IMixSku from '../../types/IMixSku'

interface IProps {
  openQpig: (mixSku: IMixSku) => void
  mixSku: IMixSku
}

const MixSkusRowPending = (props: IProps) => {
  const { mixSku, openQpig } = props

  return (
    <tr
      className='ln-c-table__row'
      key={mixSku.mixSkuId}
      id={`MixSkuId_${mixSku.mixSkuId}`}
    >
      <td className='ln-c-table__cell c-table__cell--fixed-main c-table__cell--60'>
        <Link
          className='ln-u-text-decoration-underline c-common-hover-over-cursor'
          onClick={() => {
            setCurrentPlanner('bakery')
            openQpig(mixSku)
          }}
        >
          {mixSku.skuName}
          {mixSku.isCredibleProduct === true && (
            <StarFilled
              height='25px'
              fill='black'
              className='ln-u-margin-left c-table__icon'
            />
          )}
        </Link>
      </td>
      <td className='ln-c-table__cell c-table__cell--fixed c-table__cell--40 c-table__row'>
        {mixSku.productionTarget}
      </td>
    </tr>
  )
}

export default MixSkusRowPending
