import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  Modal,
  TextButton,
} from '@jsluna/react'
import React, { useEffect, useState } from 'react'

import { IModal } from '../../types/IModal'

const SaveModal = (props: IModal) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    props.onClose()
  }

  useEffect(() => {
    setIsModalOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <div className='c-common-modal'>
      <Modal handleClose={() => closeModal()} open={isModalOpen}>
        <h4 data-testid='modalTitle'>{props.modalTitle}</h4>
        {props.modalBody && <p data-testid='modalBody'>{props.modalBody}</p>}
        <ButtonGroupWrapper>
          <ButtonGroupSecondary>
            <TextButton
              data-testid='onYes'
              onClick={() => props.onYes !== undefined && props.onYes()}
            >
              {props.modalYes ? props.modalYes : 'Yes'}
            </TextButton>
            <TextButton
              data-testid='onNo'
              onClick={() => props.onNo !== undefined && props.onNo()}
            >
              {props.modalNo ? props.modalNo : 'No'}
            </TextButton>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  )
}

export default SaveModal
