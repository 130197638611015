import React from 'react'

import { IStackBanner } from '../../../hotfood/types/IStackBanner'

interface IProps {
  banners: IStackBanner[]
  setBanners: (messages: IStackBanner[]) => void
}

// TO investigate Would it be possible to have a useContext here
// and refresh this component with a useEffect on state.banners

const BannerHolder = (props: IProps) => {
  const removeMessage = (time: Date) => {
    let found = false // a flag to indicate if the first even number is found

    const messages: IStackBanner[] = props.banners.filter((message) => {
      if (message.time === time && !found) {
        found = true
        return false // remove
      } else {
        return true // keep
      }
    })

    props.setBanners(messages)
  }

  const getLifeTime = (time: Date) => {
    const totalLifeTime = 8000 // 8 seconds
    const now = new Date()
    const milisecondsPassed = now.getTime() - time.getTime()
    if (milisecondsPassed > totalLifeTime) {
      return 0
    } else {
      return totalLifeTime - milisecondsPassed
    }
  }

  return (
    <>
      {props.banners.map((message, i) => {
        setTimeout(() => {
          removeMessage(message.time)
        }, getLifeTime(message.time))

        return <div key={i}>{message.banner}</div>
      })}
    </>
  )
}

export default BannerHolder
