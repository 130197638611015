import {
  Accordion,
  AccordionItem,
  GridItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import React, { useContext } from 'react'

import { LastEditedTooltip } from '../../../common/components/LastEditedTooltip/LastEditedTooltip'
import { Context } from '../../../common/Context/context'
import { setForecastisOpen } from '../../../common/Context/forecastDispatch'
import {
  formatPizzaName,
  salesForecastTitle,
  salesForecastToolTip,
  toMakeTodayTitle,
  toMakeTodayToolTip,
  totalMadeTitle,
  totalMadeToolTip,
} from '../../../utils/pizzaUtils'
import { IItem } from '../../types/IItem'
import { IPlannerGroupItem } from '../../types/IPlannerGroupItem'
import Incrementor from './Incrementor'

const ForecastTable = () => {
  const { state, dispatch } = useContext(Context)

  const forecastCards = (planItems: IItem[], plannerGroupIndex: number) => {
    let totalForecasted = 0
    let totalProduced = 0

    return (
      <>
        {planItems.map((planItem: IItem, planItemIndex: number) => {
          totalForecasted += state.forecast.isFuture
            ? state.forecast.editing
              ? planItem.totalSalesForecastN1
              : planItem.totalSalesForecast
            : planItem.produceQuantity
          totalProduced += planItem.quantityCompleted

          return (
            <div
              className='c-pizza-card-container c-pizza-card-row'
              key={planItemIndex}
            >
              <div className='ln-u-soft ln-u-font-weight-bold'>
                {formatPizzaName(planItem.pizzaName)}
              </div>
              {!state.forecast.editing && !state.forecast.isFuture && (
                <>
                  <GridItem size={'1/2'}>{toMakeTodayTitle}</GridItem>
                  <GridItem size={'1/2'}>{planItem.produceQuantity}</GridItem>
                  <GridItem size={'1/2'}>{totalMadeTitle}</GridItem>
                  <GridItem size={'1/2'} className='ln-u-soft-bottom'>
                    {planItem.quantityCompleted}
                  </GridItem>
                </>
              )}
              {!state.forecast.editing && state.forecast.isFuture && (
                <div className='ln-u-soft-bottom'>
                  <GridItem
                    size={'1/2'}
                    className={
                      planItem.updatedBy &&
                      planItem.updatedAt &&
                      'ln-u-margin-top*2'
                    }
                  >
                    {salesForecastTitle}
                  </GridItem>
                  <GridItem
                    size={'1/2'}
                    className={
                      planItem.updatedBy &&
                      planItem.updatedAt &&
                      'ln-u-margin-top*2'
                    }
                  >
                    {planItem.totalSalesForecast}
                  </GridItem>
                  {planItem.updatedBy && planItem.updatedAt && (
                    <GridItem
                      size={'1/1'}
                      className='ln-u-margin-top ln-u-hard-left'
                    >
                      {LastEditedTooltip(
                        planItem.updatedBy,
                        planItem.updatedAt,
                        planItem.baseSalesForecast,
                        'The original Sales Forecast is shown in brackets.',
                      )}
                    </GridItem>
                  )}
                </div>
              )}
              {state.forecast.editing && (
                <div className='ln-u-soft-bottom ln-u-margin-left'>
                  {Incrementor(planItem, plannerGroupIndex, planItemIndex)}
                </div>
              )}
            </div>
          )
        })}

        <div
          className='c-pizza-card-container c-force-white-background ln-u-border-top'
          key={`total${plannerGroupIndex}`}
        >
          <div className='ln-u-soft ln-u-font-weight-bold'>Total</div>
          <GridItem size={'1/2'}>
            {!state.forecast.isFuture ? toMakeTodayTitle : salesForecastTitle}
          </GridItem>
          <GridItem size={'1/2'}>{totalForecasted}</GridItem>
          {!state.forecast.isFuture && (
            <GridItem size={'1/2'}>{totalMadeTitle}</GridItem>
          )}
          {!state.forecast.isFuture && (
            <GridItem size={'1/2'} className='ln-u-soft-bottom'>
              {totalProduced}
            </GridItem>
          )}
        </div>
      </>
    )
  }

  const forecastTable = (planItems: IItem[], plannerGroupIndex: number) => {
    let totalForecasted = 0
    let totalProduced = 0

    return (
      <div className='c-pizza-table-container' key={plannerGroupIndex}>
        <TableContainer>
          <TableHeader>
            <TableRow>
              <TableCell className='c-table__cell---pizza-type-sku'>
                Type/SKU
              </TableCell>
              {!state.forecast.editing && !state.forecast.isFuture && (
                <>
                  <TableCell className='c-table__cell--to-make-today'>
                    {toMakeTodayTitle} {toMakeTodayToolTip}
                  </TableCell>
                  <TableCell className='c-table__cell--total-made'>
                    {totalMadeTitle} {totalMadeToolTip}
                  </TableCell>
                </>
              )}
              {state.forecast.isFuture && (
                <TableCell className='c-table__cell--pizza-sales-forecast'>
                  {salesForecastTitle} {salesForecastToolTip}
                </TableCell>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {!state.forecast.editing &&
              planItems.map((planItem: IItem, id: number) => {
                totalForecasted += state.forecast.isFuture
                  ? planItem.totalSalesForecast
                  : planItem.produceQuantity
                totalProduced += planItem.quantityCompleted

                return (
                  <TableRow className='ln-space-half' key={id}>
                    <TableCell>{formatPizzaName(planItem.pizzaName)}</TableCell>
                    {!state.forecast.isFuture && (
                      <>
                        <TableCell>{planItem.produceQuantity}</TableCell>
                        <TableCell>{planItem.quantityCompleted}</TableCell>
                      </>
                    )}
                    {state.forecast.isFuture && (
                      <TableCell className='ln-u-text-align-left'>
                        {planItem.totalSalesForecast}
                        {planItem.updatedBy && planItem.updatedAt && (
                          <div className='c-common-inline ln-u-margin-left*2'>
                            {LastEditedTooltip(
                              planItem.updatedBy,
                              planItem.updatedAt,
                              planItem.baseSalesForecast,
                              'The original Sales Forecast is shown in brackets.',
                            )}
                          </div>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            {state.forecast.editing &&
              planItems.map((planItem: IItem, planItemIndex: number) => {
                totalForecasted += planItem.totalSalesForecastN1
                totalProduced += planItem.quantityCompleted

                return (
                  <TableRow className='ln-space-half' key={planItemIndex}>
                    <TableCell>{formatPizzaName(planItem.pizzaName)}</TableCell>
                    <td className='c-production-incrementor'>
                      {Incrementor(planItem, plannerGroupIndex, planItemIndex)}
                    </td>
                  </TableRow>
                )
              })}
            <TableRow
              className='ln-space-half c-force-white-background'
              key={`total${plannerGroupIndex}`}
            >
              <TableCell className='ln-u-font-weight-bold'>Total</TableCell>
              {!state.forecast.editing && (
                <TableCell className='ln-u-font-weight-bold'>
                  {totalForecasted}
                </TableCell>
              )}
              {state.forecast.editing && (
                <TableCell className='ln-u-font-weight-bold c-production-incrementor'>
                  <div className='c-forecast-edit-total'>{totalForecasted}</div>
                </TableCell>
              )}
              {!state.forecast.isFuture && (
                <TableCell className='ln-u-text-align-left ln-u-font-weight-bold'>
                  {totalProduced}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </TableContainer>
      </div>
    )
  }

  return (
    <>
      <div className='c-accordion-container ln-u-soft-bottom'>
        <Accordion multipleOpen titleElement='h3'>
          {state.forecast.forecastPlan.forecastPlannerGroups
            .slice()
            .sort(
              (
                planGroupA: IPlannerGroupItem,
                planGroupB: IPlannerGroupItem,
              ): number =>
                planGroupA.positionOnOutput < planGroupB.positionOnOutput
                  ? -1
                  : 1,
            )
            .map(
              (plannerGroup: IPlannerGroupItem, plannerGroupIndex: number) => (
                <AccordionItem
                  onClick={() =>
                    dispatch(
                      setForecastisOpen(
                        plannerGroupIndex,
                        !plannerGroup.isOpen,
                      ),
                    )
                  }
                  defaultOpen={plannerGroup.isOpen}
                  key={plannerGroupIndex}
                  title={plannerGroup.plannerGroupName}
                >
                  {forecastTable(plannerGroup.items, plannerGroupIndex)}
                  {forecastCards(plannerGroup.items, plannerGroupIndex)}
                </AccordionItem>
              ),
            )}
        </Accordion>
      </div>
    </>
  )
}

export default ForecastTable
