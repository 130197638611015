import { ErrorCircle } from '@jsluna/icons'
import { Card, FilledButton, GridItem } from '@jsluna/react'
import moment from 'moment'
import React, { useContext, useState } from 'react'

import { useApiClient } from '../../../common/AppContext/appContext'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import ErrorBanner from '../../../common/components/Banners/ErrorBanner'
import { addBannerMessage } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { setBatchItemSoldOut } from '../../api/displayApi'
import { IBatchItemDisplay } from '../../types/IBatchItemDisplay'
import SoldOutAlertModal from './SoldOutModal'

interface IProps {
  batchItem: IBatchItemDisplay
  removeBatchItem: () => void
  refreshBatchItems: () => void
}

const BatchItemCard = (props: IProps) => {
  const { dispatch } = useContext(Context)
  const [isSoldOutModalOpen, setIsSoldOutModalOpen] = useState(false)

  const apiClient = useApiClient()

  return (
    <>
      <GridItem
        key={props.batchItem.batchItemId}
        size={{ default: '1/1' }}
        className='ln-u-hard'
        data-testid={`batchitem-${props.batchItem.batchItemId}`}
      >
        <Card className='ln-u-margin-bottom*2 ln-u-margin-right*2 ln-u-hard c-display-batch-card'>
          <div className='ln-u-padding-bottom*2 ln-u-padding-right*3 ln-u-soft-top'>
            <GridItem
              className='ln-c-label ln-u-font-weight-bold c-display-batch-card-title'
              key={`${props.batchItem.batchItemId}${props.batchItem.turboServe ? 'ts' : ''}-Text`}
              size={{ default: props.batchItem.turboServe ? '2/3' : '1/1' }}
            >
              {props.batchItem.skuName}
            </GridItem>
            {props.batchItem.turboServe && (
              <GridItem
                className='c-display-batch-card-label'
                size={{ default: '1/3' }}
              >
                Turbo-serve
              </GridItem>
            )}

            <GridItem
              className='ln-u-margin-bottom*2'
              size={{ default: '1/1' }}
            >
              {`Dispose at ${moment.utc(props.batchItem.toBeDisposedAt).local().format('HH.mm')}`}
            </GridItem>

            <GridItem
              key={`${props.batchItem.batchItemId}-Button`}
              size={{ default: '1/1' }}
            >
              <FilledButton
                fullWidth
                onClick={() => setIsSoldOutModalOpen(true)}
              >
                Remove
              </FilledButton>
            </GridItem>

            {isSoldOutModalOpen && (
              <SoldOutAlertModal
                skuName={props.batchItem.skuName}
                handleClose={() => setIsSoldOutModalOpen(false)}
                handleSoldOut={() => {
                  setIsSoldOutModalOpen(false)
                  setBatchItemSoldOut(apiClient, {
                    batchItemId: props.batchItem.batchItemId,
                    turboServe: props.batchItem.turboServe,
                  })
                    .then((res) => {
                      if (res.isSuccess) {
                        props.removeBatchItem()
                        dispatch(
                          addBannerMessage(
                            <ConfirmationBanner
                              message={`'${props.batchItem.skuName}' have been successfully registered as sold out.`}
                            />,
                          ),
                        )
                        setIsSoldOutModalOpen(false)
                      } else {
                        props.refreshBatchItems()
                        dispatch(
                          addBannerMessage(
                            <ErrorBanner
                              message={`An error occurred whilst attempting to set the '${props.batchItem.skuName}'
                             batch as sold out - ${res.message}`}
                              icon={<ErrorCircle />}
                            />,
                          ),
                        )
                      }
                    })
                    .catch((e) => {
                      props.refreshBatchItems()
                      dispatch(
                        addBannerMessage(
                          <ErrorBanner
                            message={`An error occurred whilst attempting to set the '${props.batchItem.skuName}'
                           batch as sold out - ${(e as Error).message}`}
                            icon={<ErrorCircle />}
                          />,
                        ),
                      )
                    })
                }}
                isOpen={isSoldOutModalOpen}
              />
            )}
          </div>
        </Card>
      </GridItem>
    </>
  )
}

export default BatchItemCard
