import { IForecastPlan } from '../../pizza/types/IForecastPlan'
import { IPlannerGroupItem } from '../../pizza/types/IPlannerGroupItem'
import { ActionType } from './reducer'

export interface ISetPlanDates {
  type: ActionType.forecast_setPlanDates
  payload: any[]
}

export interface ISetPizzaPlan {
  type: ActionType.forecast_setForecastPlan
  payload: IForecastPlan
}

export interface ISetForecastPlanOnSave {
  type: ActionType.forecast_setForecastPlanOnSave
  payload: {
    plannerGroups: IPlannerGroupItem[]
    forecastPlan: IForecastPlan
  }
}

export interface ISetSelectedPlanDateIndex {
  type: ActionType.forecast_setSelectedPlanDateIndex
  payload: number
}

export interface ISetForecastEdit {
  type: ActionType.forecast_setEditing
  payload: boolean
}

export interface ISetForecastSaveModal {
  type: ActionType.forecast_setForecastSaveModal
  payload: boolean
}

export interface ISetIsFuture {
  type: ActionType.forecast_setIsFuture
  payload: boolean
}

export interface ISetSalesForecastValue {
  type: ActionType.forecast_setSalesForecastValue
  payload: {
    plannerGroupIndex: number
    planItemIndex: number
    value: number
  }
}

export interface ISetForecastisOpen {
  type: ActionType.forecast_setIsOpen
  payload: {
    plannerGroupIndex: number
    value: boolean
  }
}

export const setPlanDates = (planDates: any[]): ISetPlanDates => ({
  payload: planDates,
  type: ActionType.forecast_setPlanDates,
})

export const setForecastPlan = (forecastPlan: IForecastPlan): ISetPizzaPlan => {
  forecastPlan.forecastPlannerGroups.forEach((pg) => {
    pg.isOpen = false
    pg.items.forEach((pi) => (pi.totalSalesForecastN1 = pi.totalSalesForecast))
  })

  return {
    payload: forecastPlan,
    type: ActionType.forecast_setForecastPlan,
  }
}

export const setForecastPlanOnSave = (
  plannerGroups: IPlannerGroupItem[],
  forecastPlan: IForecastPlan,
): ISetForecastPlanOnSave => {
  forecastPlan.forecastPlannerGroups.forEach((pg) =>
    pg.items.forEach((pi) => (pi.totalSalesForecastN1 = pi.totalSalesForecast)),
  )

  forecastPlan.forecastPlannerGroups.forEach((x) => {
    const existingPlannerGroup = plannerGroups.find(
      (y) => y.plannerGroupId === x.plannerGroupId,
    )
    if (existingPlannerGroup !== undefined) {
      x.isOpen = existingPlannerGroup.isOpen
    }
  })

  return {
    payload: { plannerGroups, forecastPlan },
    type: ActionType.forecast_setForecastPlanOnSave,
  }
}

export const setSelectedPlanDateIndex = (
  selectedPlanIndex: number,
): ISetSelectedPlanDateIndex => ({
  payload: selectedPlanIndex,
  type: ActionType.forecast_setSelectedPlanDateIndex,
})

export const setForecastEditing = (value: boolean): ISetForecastEdit => ({
  payload: value,
  type: ActionType.forecast_setEditing,
})

export const setForecastSaveModal = (
  value: boolean,
): ISetForecastSaveModal => ({
  payload: value,
  type: ActionType.forecast_setForecastSaveModal,
})

export const setIsFuture = (value: boolean): ISetIsFuture => ({
  payload: value,
  type: ActionType.forecast_setIsFuture,
})

export const setSalesForecastValue = (
  plannerGroupIndex: number,
  planItemIndex: number,
  value: number,
): ISetSalesForecastValue => ({
  payload: { plannerGroupIndex, planItemIndex, value },
  type: ActionType.forecast_setSalesForecastValue,
})

export const setForecastisOpen = (
  plannerGroupIndex: number,
  value: boolean,
): ISetForecastisOpen => ({
  payload: { plannerGroupIndex, value },
  type: ActionType.forecast_setIsOpen,
})
