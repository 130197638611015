import {
  Card,
  FlagBody,
  FlagComponent,
  FlagWrapper,
  GridItem,
  GridWrapper,
  TextInputField,
} from '@jsluna/react'
import { StarFilled } from '@sainsburys-tech/icons'
import dayjs from 'dayjs'
import React from 'react'
import { isSafari } from 'react-device-detect'

import { LastEditedTooltip } from '../../../common/components/LastEditedTooltip/LastEditedTooltip'
import AppTooltip from '../../../common/components/Tooltip/AppTooltip'
import { IPlan } from '../../types/IPlan'
import { IPlanItem } from '../../types/IPlanItem'
import { IPlannerGroup } from '../../types/IPlannerGroup'

interface IProps {
  plan: IPlan
  controlGroupId: number
  editing: boolean
  onProductionTargetChange: (
    planItemId: number,
    updatedQuantity: number,
  ) => void
}

const SalesPlannerTable = (props: IProps) => {
  const getCards = (plannerGroup: IPlannerGroup) =>
    plannerGroup.planItems
      .sort((planItemA: IPlanItem, planItemB: IPlanItem): number =>
        planItemA.positionOnOutput < planItemB.positionOnOutput ? -1 : 1,
      )
      .map((planItem: IPlanItem, i: number) => (
        <div
          key={i}
          className={`ln-u-soft ${i % 2 !== 0 ? 'c-bakery-card-alt-row' : ''}`}
        >
          <h5>{planItem.skuName}</h5>
          {/* <GridItem className='ln-u-font-weight-bold c-bakery-card' size={'1/2'}>Sell out time</GridItem>
          <GridItem className='c-bakery-card' size={'1/2'}>
            {planItem.previousWeekLastSaleTime !== null ?
              convertSecondsToHoursMinutes(planItem.previousWeekLastSaleTime)
              : '-'
            }
          </GridItem> */}

          {isTodayOrFuture() && (
            <>
              <GridItem
                className={`ln-u-font-weight-bold ${
                  planItem.updatedBy && planItem.updatedAt
                    ? 'c-bakery-card-with-tooltip'
                    : 'c-bakery-card'
                }`}
                size={'1/2'}
              >
                {getSalesForecastTitle(props.plan)}
              </GridItem>
              {props.editing === true && planItem.isSkuEditable === true ? (
                <>
                  <GridItem size={'1/2'}>
                    <TextInputField
                      value={planItem.totalSalesForecast.toString()}
                      name={planItem.planItemId.toString()}
                      onChange={onProduceInput}
                      onClick={onProductInputFocus}
                      className='ln-u-flush-bottom c-bakery-plan-item-input'
                      type='number'
                      autoComplete='off'
                      id='editForecast'
                    />
                  </GridItem>
                </>
              ) : (
                <GridItem
                  className={`${planItem.updatedBy && planItem.updatedAt ? '' : 'c-bakery-card'}`}
                  size={'1/2'}
                >
                  {planItem.totalSalesForecast} &nbsp;
                  {planItem.updatedBy &&
                    planItem.updatedAt &&
                    LastEditedTooltip(
                      planItem.updatedBy,
                      planItem.updatedAt,
                      planItem.baseSalesForecast,
                      'The original Sales Forecast is shown in brackets.',
                    )}
                </GridItem>
              )}
            </>
          )}
          {isTodayOrPastDay() && (
            <>
              <GridItem
                className='ln-u-font-weight-bold c-bakery-card'
                size={'1/2'}
              >
                {getProductionTarget(props.plan)}
              </GridItem>
              <GridItem className='c-bakery-card' size={'1/2'}>
                {planItem.produceQuantity}
              </GridItem>
            </>
          )}
          {isPastDay() && (
            <>
              <GridItem
                className='ln-u-font-weight-bold c-bakery-card'
                size={'1/2'}
              >
                Confirmed Completion
              </GridItem>
              <GridItem className='c-bakery-card' size={'1/2'}>
                {planItem.quantityCompleted}/{planItem.produceQuantity}
              </GridItem>
            </>
          )}
        </div>
      ))

  const getProductionTarget = (plan: IPlan) =>
    plan.eventName ? `${plan.eventName} Production target` : 'Production target'

  const getSalesForecastTitle = (plan: IPlan) =>
    plan.eventName ? `${plan.eventName} Sales forecast` : 'Sales forecast'

  const getTableRow = (plannerGroup: IPlannerGroup) =>
    plannerGroup.planItems
      .sort((planItemA: IPlanItem, planItemB: IPlanItem): number =>
        planItemA.positionOnOutput < planItemB.positionOnOutput ? -1 : 1,
      )
      .map((planItem: IPlanItem) => (
        <tr key={planItem.planItemId} className='ln-c-table__row'>
          <td className='ln-u-soft'>
            {planItem.skuName}
            {planItem.isCredibleProduct === true && (
              <StarFilled
                height='25px'
                fill='black'
                className='ln-u-margin-left c-table__icon'
              />
            )}
          </td>
          <td className='ln-c-table__cell c-table__cell--20'>
            {/* {planItem.previousWeekLastSaleTime !== null ?
              convertSecondsToHoursMinutes(planItem.previousWeekLastSaleTime)
              : '-'
            } */}
          </td>
          {props.editing && planItem.isSkuEditable ? (
            <>
              <td className='ln-c-table__cell c-table__cell--fixed'>
                <div className='ln-u-1/2@md ln-u-1/1@xs'>
                  <TextInputField
                    value={planItem.totalSalesForecast.toString()}
                    name={planItem.planItemId.toString()}
                    onChange={onProduceInput}
                    onClick={onProductInputFocus}
                    className='ln-u-flush-bottom c-bakery-plan-item-input'
                    type='number'
                    autoComplete='off'
                    id='editForecast'
                  />
                </div>
              </td>
            </>
          ) : (
            <>
              {isTodayOrFuture() && (
                <td className='ln-c-table__cell c-table__cell--sales-forecast c-table__row'>
                  {planItem.totalSalesForecast} &nbsp;
                  {planItem.updatedBy &&
                    planItem.updatedAt &&
                    LastEditedTooltip(
                      planItem.updatedBy,
                      planItem.updatedAt,
                      planItem.baseSalesForecast,
                      'The original Sales Forecast is shown in brackets.',
                    )}
                </td>
              )}
            </>
          )}
          {isTodayOrPastDay() && (
            <td className='ln-c-table__cell c-table__cell--fixed'>
              {planItem.produceQuantity}
            </td>
          )}
          {isPastDay() && (
            <td className='ln-c-table__cell c-table__cell--fixed'>
              {planItem.quantityCompleted}/{planItem.produceQuantity}
            </td>
          )}
        </tr>
      ))

  const getTables = () => {
    if (props.plan.planControlGroups === null) {
      return <div className='ln-u-text-align-center'>A plan was not found</div>
    }

    if (props.plan.planControlGroups.length === 0) {
      return (
        <div className='ln-u-text-align-center'>Plan skus were not found</div>
      )
    }

    const planControlGroup = props.plan.planControlGroups.find(
      (p) => p.controlGroupId === props.controlGroupId,
    )

    if (planControlGroup === undefined) {
      return (
        <div className='ln-u-text-align-center'>
          A plan was not found for this Control Group
        </div>
      )
    }

    return planControlGroup.plannerGroups
      .sort((planGroupA: IPlannerGroup, planGroupB: IPlannerGroup): number =>
        planGroupA.positionOnOutput < planGroupB.positionOnOutput ? -1 : 1,
      )
      .map((plannerGroup, i) => (
        <Card key={plannerGroup.plannerGroupId}>
          <GridWrapper>
            <GridItem size={'1/2'}>
              <h5>{plannerGroup.plannerGroupName}</h5>
            </GridItem>
          </GridWrapper>

          {/* desktop/tablet view */}
          <div className='ln-c-table-container c-bakery-table-container'>
            <table className='ln-c-table'>
              <thead
                className={`${i !== 0 ? 'ln-c-table__header--hidden' : 'ln-c-table__header'}`}
              >
                <tr className='ln-c-table__row ln-c-table__header-row'>
                  <th className='ln-c-table__header-cell'>Type/SKU</th>
                  <th className='ln-c-table__header-cell'>
                    {/* <div className="c-table__header-cell--sell-out-time">
                      <FlagWrapper>
                        <FlagBody>
                          Sell out time
                        </FlagBody>
                        <FlagComponent>
                          <AppTooltip message={sellOutTimeTooltipText} />
                        </FlagComponent>
                      </FlagWrapper>
                    </div> */}
                  </th>
                  {isTodayOrFuture() && (
                    <th
                      className='ln-c-table__header-cell'
                      data-testid='sales-forecast-title'
                      id='sales-forecast-title'
                    >
                      {getSalesForecastTitle(props.plan)}
                    </th>
                  )}
                  {isTodayOrPastDay() && (
                    <th className='ln-c-table__header-cell'>
                      <FlagWrapper>
                        <FlagBody>
                          <span
                            data-testid='production-target-title'
                            id='production-target-title'
                          >
                            {getProductionTarget(props.plan)}
                          </span>
                        </FlagBody>
                        <FlagComponent>
                          <AppTooltip message={productionTargetTooltipText} />
                        </FlagComponent>
                      </FlagWrapper>
                    </th>
                  )}
                  {isPastDay() === true && (
                    <th className='ln-c-table__header-cell'>
                      Confirmed completion
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className='ln-c-table__body'>
                {getTableRow(plannerGroup)}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  {isTodayOrFuture() && <td />}
                  {isTodayOrPastDay() && <td />}
                  <td className='c-table__footer-cell' colSpan={3}>
                    <div className='c-split-cell'>
                      <div
                        className='c-bakery-split-cell__left'
                        data-testid='total-produced-or-sales'
                        id='totalProducedOrSales'
                      >
                        {getTotalProduceOrSales(plannerGroup)}
                      </div>
                    </div>
                  </td>
                </tr>
                {plannerGroup.displayFlourWeight === true && (
                  <tr>
                    <td />
                    <td className='c-table__footer-cell' colSpan={3}>
                      <div className='c-split-cell'>
                        <div
                          className='c-bakery-split-cell__right'
                          data-testid='flour-weight'
                          id='flourWeight'
                        >
                          {getYield(plannerGroup)}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>

          {/* mobile view */}
          <div className='c-bakery-card-container'>
            {getCards(plannerGroup)}
            {plannerGroup.displayFlourWeight === true && (
              <div className='ln-u-font-weight-bold c-bakery-card-flour-weight'>
                {getYield(plannerGroup)}
              </div>
            )}
          </div>
        </Card>
      ))
  }

  const getTotalProduceOrSales = (plannerGroup: IPlannerGroup) => {
    let totalProduce = 0
    if (isTodayOrPastDay()) {
      plannerGroup.planItems.forEach(
        (planItem) => (totalProduce += planItem.produceQuantity),
      )
    } else {
      plannerGroup.planItems.forEach(
        (planItem) => (totalProduce += planItem.totalSalesForecast),
      )
    }

    return totalProduce
  }

  const getYield = (plannerGroup: IPlannerGroup) => {
    let totalWeight = 0
    plannerGroup.planItems.forEach((planItem) => {
      if (isTodayOrPastDay()) {
        totalWeight +=
          planItem.unitWeightInGrams *
          planItem.produceQuantity *
          planItem.packSize
      } else {
        totalWeight +=
          planItem.unitWeightInGrams *
          planItem.totalSalesForecast *
          planItem.packSize
      }
    })
    totalWeight = totalWeight / 1000

    /* Remove 0 after decimal if not required */
    if (totalWeight.toFixed(1) !== totalWeight.toFixed(2)) {
      totalWeight = Number(totalWeight.toFixed(2))
    } else if (Math.round(totalWeight) !== Number(totalWeight.toFixed(1))) {
      totalWeight = Number(totalWeight.toFixed(1))
    }

    /* No. of Bags calculated from Rounded Weight Total */
    let bags = totalWeight / 16
    /* Remove 0 after decimal if not required */
    if (Math.round(bags) !== bags) {
      bags = Number(bags.toFixed(2))
    }
    let bagSuffix = 'bags'
    if (bags === 1) {
      bagSuffix = 'bag'
    }

    return `${totalWeight}kg / ${bags} ${bagSuffix}`
  }

  const isPastDay = () => dayjs(props.plan.planDate).diff(dayjs(), 'day') < 0

  const isTodayOrFuture = () =>
    dayjs(props.plan.planDate).diff(dayjs(), 'day') >= 0

  const isTodayOrPastDay = () =>
    dayjs(props.plan.planDate).diff(dayjs(), 'day', true) <= 0

  const onProduceInput = (event: React.FormEvent<HTMLInputElement>) => {
    const regexZeroToThreeDigitsOnly = /^\d{0,3}$/
    if (regexZeroToThreeDigitsOnly.test(event.currentTarget.value)) {
      props.onProductionTargetChange(
        Number(event.currentTarget.name),
        Number(event.currentTarget.value),
      )
    }
  }

  const onProductInputFocus = (event: React.FormEvent<HTMLInputElement>) => {
    if (isSafari) {
      /* currentTarget.select() doesn't work on Safari */
      try {
        event.currentTarget.focus()
        event.currentTarget.setSelectionRange(
          0,
          event.currentTarget.value.length,
        )
      } catch (ex) {
        console.log(ex)
        /* Set selection error - usually for chrome in iPad debug mode */
        event.currentTarget.select()
      }
    } else {
      event.currentTarget.select()
    }
  }

  const productionTargetTooltipText = `Production target contains today's expected sales
    plus carryover for tomorrow's multiday life products.`

  // const sellOutTimeTooltipText =
  //   'Time a product was sold out at last week.'

  return <div>{getTables()}</div>
}

export default SalesPlannerTable
