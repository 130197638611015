import moment from 'moment'

import { AlertMessageKey } from '../enums/AlertMessageKey'
import { FormErrorMessageKey } from '../enums/FormErrorMessageKey'
import { TempCheckCounterStatus } from '../enums/TempCheckCounterStatus'
import { TempCheckFor } from '../enums/TempCheckFor'
import * as Position from '../enums/TemperatureCheckPosition'
import { IBatch } from '../types/IBatch'
import { IBatchTemperature } from '../types/IBatchTemperature'
import ICounterCheck from '../types/ICounterCheck'
import { ITemperatureData } from '../types/ITemperatureData'

export const afternoonHour = 12
export const midday = moment(new Date())
  .hours(12)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)
export const midnight = moment(new Date())
  .add(1, 'days')
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)

// if you want to test at a different 'now' time, please put a signed(+/-) hour into param
// ex. getTimeNow(-6)
export const getTimeNow = (testHourOffset?: number) => {
  const now = new Date()
  if (testHourOffset !== undefined) {
    const mockNow = new Date(now.getTime() + 3600000 * testHourOffset)
    return mockNow
  }

  return now
}

export const getRequiredTemperatureCount = (
  batch: IBatch | IBatchTemperature,
) => {
  let itemCount = 0

  for (const i of batch.items) {
    itemCount += i.portionSize * i.quantityProduced
  }

  return Math.min(3, itemCount)
}

export const isBatchDisposed = (batch: IBatchTemperature) => {
  const count = getRequiredTemperatureCount(batch)

  // get last set of temperatures taken
  const temperatures = batch.temperatures!.slice(
    (batch.temperaturesCount - 1) * count,
    (batch.temperaturesCount - 1) * count + count,
  )

  return (
    batch.temperaturesCount === 3 &&
    temperatures.filter(
      (t) =>
        parseFloat(t.temperature) <
        getRequiredTemperatureDgree(TempCheckFor.Batch),
    ).length > 0
  )
}

export const invalidTemperatureInputs = (tempData: ITemperatureData[]) =>
  tempData.filter(
    (t) =>
      t.temperature === '' ||
      (t.temperature !== 'n/a' &&
        t.temperature.match(/[0-9]*\.[0-9]+/g) === null),
  )

// validate that user has entered either "n/a" or numeric value (with decimal point) in each temperature field
export const allTemperaturesExist = (tempData: ITemperatureData[]) => {
  const invalidTemps = invalidTemperatureInputs(tempData)
  if (invalidTemps.length !== 0) {
    return [
      invalidTemps,
      FormErrorMessageKey.EmptyOrNoDecimalTemperature,
      false,
    ]
  }

  return [[], FormErrorMessageKey.NONE, true]
}

// validate temperature data of each position (1, 2, 3, 4)
// input : ITemperatureData[]
// output : [isValid, errorMessage]
// 1) priority to check - no temp OR no decimal temp
// 2) under 20Deg temp check
export const validateTemperatures = (tempData: ITemperatureData[]) => {
  const invalidTemps = invalidTemperatureInputs(tempData)
  if (invalidTemps.length !== 0) {
    return [
      invalidTemps,
      FormErrorMessageKey.EmptyOrNoDecimalTemperature,
      false,
    ]
  }

  const under20DegTemps: ITemperatureData[] = tempData.filter(
    (t) => Number(t.temperature) < 20,
  )
  if (under20DegTemps.length !== 0) {
    return [under20DegTemps, FormErrorMessageKey.Under20DegTemperature, false]
  }

  return [[], FormErrorMessageKey.NONE, true]
}

// To get a minimum requirement of Temperature in each scenario
export const getRequiredTemperatureDgree = (checkFor: TempCheckFor): number => {
  switch (checkFor) {
    case TempCheckFor.Batch:
      return 75.0

    case TempCheckFor.CabinetFirstAir:
      return 75.0

    case TempCheckFor.CabinetFollowing:
      return 63.0

    case TempCheckFor.TurboServe:
      return getTimeNow().getHours() < afternoonHour ? 75.0 : 63.0

    default:
      return 75.0
  }
}

// validate a counter with temperatures on Counter Temperature check
export const validCounterTemperatures = (
  data: ITemperatureData[],
  isTurboServe: boolean,
  counter: ICounterCheck,
) => {
  const everChecked = counter.everChecked || false

  // a happy case
  // * n/a input will be ignored at a validation check
  if (isTurboServe) {
    let valid = true
    const warmer: number[] = []

    for (let count = 1; count <= data.length; count++) {
      const temp = data.find(
        (t) => (t.position as number) === count,
      )!.temperature
      const tempRange = counter.shelves!.find(
        (s) => s.shelfNumber === count && s.isAfternoon === !isAM(),
      )!

      // if it's first check, any case of under minimum temp should show 'under minimum' message
      if (!everChecked && Number(temp) < tempRange.minimumTemperature) {
        return [false, AlertMessageKey.ShelfUnder75DegTemperature]
      }

      valid =
        valid &&
        (temp === 'n/a' || Number(temp) >= tempRange.minimumTemperature)

      // warmer than expected?
      if (Number(temp) > tempRange.maximumTemperature) {
        warmer.push(count)
      }
    }

    if (valid) {
      // only display the "warmer than expected" message if all other temperatures are valid
      switch (warmer[warmer.length - 1]) {
        case 1:
          return [true, AlertMessageKey.TopShelfOverMaxTemperature]
        case 2:
          return [true, AlertMessageKey.MiddleFirstShelfOverMaxTemperature]
        case 3:
          return [true, AlertMessageKey.MiddleSecondShelfOverMaxTemperature]
        case 4:
          return [true, AlertMessageKey.BottomShelfOverMaxTemperature]
      }

      return [true, AlertMessageKey.NONE]
    }
  } else {
    if (
      data.filter((t) => t.temperature === 'n/a' || Number(t.temperature) >= 75)
        .length === data.length ||
      (everChecked &&
        data.filter(
          (t) => t.temperature === 'n/a' || Number(t.temperature) >= 63,
        ).length === data.length)
    ) {
      return [true, AlertMessageKey.NONE]
    }

    // if it's first check, any case of under 75 temp check should show just 'under 75' message
    if (
      !everChecked &&
      data.filter((t) => Number(t.temperature) < 75).length > 0
    ) {
      return [false, AlertMessageKey.CabinetUnder75DegTemperature]
    }
  }

  // below 55 degree
  // OR
  // between 55 and 63
  return data.filter((t) => Number(t.temperature) < 55).length > 0
    ? isTurboServe
      ? [false, AlertMessageKey.ShelfBelow55DegTemperature]
      : [false, AlertMessageKey.CabinetBelow55DegTemperature]
    : isTurboServe
      ? [false, AlertMessageKey.ShelfBetween63And55DegTemperature]
      : [false, AlertMessageKey.CabinetBetween63And55DegTemperature]
}

export const getTextAMorPM = () =>
  getTimeNow().getHours() < afternoonHour ? 'AM' : 'PM'
export const isAM = () => getTimeNow().getHours() < afternoonHour

export const getCounterByNumber = (
  counters: ICounterCheck[],
  counterNumber: number,
) => counters.find((s) => s.counterNumber === counterNumber)

// validate the temperature in a xx.x format and each dynamic temperature inputs are updated on the matching object.
export const updateCounterTemperatureByValue = (
  value: string,
  position: Position.BatchTray | Position.CabinetWindow | Position.TurboShelf,
  temperatures: ITemperatureData[],
): ITemperatureData[] => {
  const targets = temperatures.reduce(
    (accTemperatures: ITemperatureData[], current) => {
      if (current.position === position) {
        if (value === 'n/a') {
          current.temperature = 'n/a'
          return [...accTemperatures, current]
        }

        const matches = value.match(/^\d{0,2}[.]|[\d]{1,2}/gm)
        if (!matches || matches.length === 0) {
          current.temperature = ''
          return [...accTemperatures, current]
        }

        current.temperature =
          matches[0][matches[0].length - 1] === '.' && matches[1]
            ? matches[0].concat(matches[1].substring(0, 1))
            : matches[0]

        return [...accTemperatures, current]
      }

      return [...accTemperatures, current]
    },
    [],
  )

  return targets
}

export const getCounterTemperatureValueByPosition = (
  position: Position.BatchTray | Position.CabinetWindow | Position.TurboShelf,
  temperatures: ITemperatureData[],
): string => {
  const targetData = temperatures.find((t) => t.position === position)
  if (!targetData) {
    return ''
  }

  return targetData.temperature
}

// is all of counters completed to check under the required timeframe?
// CounterStatus should be 'Ready' for all
export const isAllTemperatureChecked = (counters: ICounterCheck[]) => {
  if (
    !counters ||
    counters.length === 0 ||
    counters.filter((c) => !c.takenAt).length > 0
  ) {
    return false
  }
  return (
    counters.filter((c) => c.checkedStatus === TempCheckCounterStatus.Ready)
      .length === 0
  )
}

// just check if all of cabinets or shelves have ever been checked before
// serve-over : ignore faulty cabinets to evaluate the first check
// turbo-serve : evaluate it according to am or pm only
export const hasAnyCounterToFirstCheckFrom = (
  counters: ICounterCheck[],
  isTurboServeMode: boolean,
): boolean => {
  if (isTurboServeMode) {
    return isAM()
  }

  return (
    counters.filter((c: ICounterCheck) => !c.isFaulty && !c.everChecked)
      .length > 0
  )
}

export const getCurrentCheckedTimeInOrder = (
  counters: ICounterCheck[],
  isTurboServe: boolean,
  isAscendingOrder: boolean,
  ignoreBaseTime: boolean,
) => {
  const time = getTimeNow()
  const currentHour = moment(time).get('hours')

  // two hours before now for cabinet(serve-over)
  const serveOverBaseTime = moment(time).add(-2, 'hours')

  const times = counters.reduce(
    (accTimes: Date[], current: ICounterCheck): Date[] => {
      const dbTimeToLocal = moment.utc(current.takenAt).local()
      // cabinet : any db times which are bigger than baseTime (= 2hour ago from now) will be collected
      if (!isTurboServe) {
        return current.takenAt &&
          (ignoreBaseTime ||
            (!ignoreBaseTime &&
              serveOverBaseTime.diff(dbTimeToLocal, 'hours', true) < 0))
          ? [...accTimes, current.takenAt]
          : [...accTimes]
      }

      return current.takenAt &&
        ((currentHour < afternoonHour &&
          moment.utc(current.takenAt).local().get('hours') < afternoonHour) ||
          (currentHour >= afternoonHour &&
            moment.utc(current.takenAt).local().get('hours') >= afternoonHour))
        ? [...accTimes, current.takenAt]
        : [...accTimes]
    },
    [],
  )

  // return earliest time (moment in local) in order (if exceptional case of no db times,
  // it will be return 00:00, but will not happen)
  return isAscendingOrder
    ? moment
        .utc(
          times.length
            ? times.reduce((pre, cur) => (pre > cur ? cur : pre))
            : undefined,
        )
        .local()
    : moment
        .utc(
          times.length
            ? times.reduce((pre, cur) => (cur > pre ? cur : pre))
            : undefined,
        )
        .local()
}

export const getNextTimeToCheck = (
  counters: ICounterCheck[],
  isTurboServe: boolean,
) => {
  // on cabinet, next time to check will be in next 2hours from the earliest time checked
  if (!isTurboServe) {
    return getCurrentCheckedTimeInOrder(
      counters,
      isTurboServe,
      true,
      false,
    ).add(2, 'hours')
  }

  // on shelf, next time to check will be AM or PM
  // 1) now is AM and if all of shelves are checked at AM, => afternoonHour.00,
  // 2) now is AM and if some of shelves are checked at AM, => now,
  // 3) now is PM and if all of shelves are checked at PM, => 24.00 = 00.00,
  // 4) now is PM and if some of shelves are checked at PM, => now,
  const now = moment(getTimeNow())

  const AMTempCount = counters.filter(
    (c: ICounterCheck) =>
      c.takenAt && moment.utc(c.takenAt).local().get('hours') < afternoonHour,
  ).length
  const PMTempCount = counters.filter(
    (c: ICounterCheck) =>
      c.takenAt && moment.utc(c.takenAt).local().get('hours') >= afternoonHour,
  ).length

  if (now.get('hours') < afternoonHour) {
    return counters.length > 0 && counters.length === AMTempCount
      ? now.hours(afternoonHour).minutes(0).seconds(0).milliseconds(0)
      : now
  }

  return counters.length > 0 && counters.length === PMTempCount
    ? now.hours(0).minutes(0).seconds(0).milliseconds(0).add(1, 'days')
    : now
}

export const getNextTimeToCheckMessage = (
  counters: ICounterCheck[],
  isTurboServe: boolean,
) => {
  const nextTime = getNextTimeToCheck(counters, isTurboServe)

  return !isTurboServe ||
    (isTurboServe && nextTime.get('hours') === afternoonHour)
    ? `Please complete your next check at ${nextTime.format('HH:mm')}.`
    : ''
}

export const updateCounterCheckStatusByTime = (
  counter: ICounterCheck,
  isTurboServe: boolean,
  takenAt?: Date,
) => {
  if (takenAt === undefined) {
    return {
      ...counter,
      checkedStatus: TempCheckCounterStatus.Ready,
      everChecked: false,
    }
  }

  const now = moment(new Date())

  // utc based DB time should be converted to local time to compare
  const takenTime = moment.utc(takenAt).local()
  if (!isTurboServe) {
    return {
      ...counter,
      checkedStatus:
        now.diff(takenTime, 'hours', true) < 2.0
          ? TempCheckCounterStatus.Checked
          : TempCheckCounterStatus.Ready,
      everChecked: true,
      takenAt,
    }
  }

  return {
    ...counter,
    checkedStatus:
      (now.get('hours') < afternoonHour &&
        takenTime.get('hours') < afternoonHour) ||
      (now.get('hours') >= afternoonHour &&
        takenTime.get('hours') >= afternoonHour)
        ? TempCheckCounterStatus.Checked
        : TempCheckCounterStatus.Ready,
    everChecked: true,
    takenAt,
  }
}

// following method assumes that batch.temperatures only contain last set of temperatures taken
export const isOutOfOven = (batch: IBatch) =>
  batch.temperatures
    ? batch.temperatures.filter((t) => parseFloat(t.temperature) >= 75.0)
        .length === getRequiredTemperatureCount(batch)
    : false
