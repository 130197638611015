import {
  Card,
  FilledButton,
  GridItem,
  GridWrapper,
  OutlinedButton,
} from '@jsluna/react'
import React from 'react'

import { MixStageEnum } from '../../enums/MixStageEnum'
import { IMix } from '../../types/IMix'
import sortMixes from '../../utils/sortMixes'
import WaveMixCardTitle from './WaveMixCardTitle'

interface IWaveMixProps {
  mixesForWave: IMix[]
  handleMixActions: (mix: IMix, nextStatus?: MixStageEnum) => Promise<void>
}

const WaveMixCard = (props: IWaveMixProps) => {
  const getYieldSize = (mix: IMix) => {
    let bagsLabel = 'bags'
    if (mix.yieldBags === 1) {
      bagsLabel = 'bag'
    }
    return (
      <span>
        {mix.yieldKg}kg&nbsp;/&nbsp;{mix.yieldBags}&nbsp;{bagsLabel}
      </span>
    )
  }

  const isMixInProgress = (mix: IMix) =>
    mix.mixStatusStageId === (MixStageEnum.InProgress as number)
  const isMixInPending = (mix: IMix) =>
    mix.mixStatusStageId === (MixStageEnum.Pending as number)
  const getPendingButtonText = (mix: IMix) =>
    mix.isReadonly === true
      ? 'View'
      : mix.enabled !== true
        ? 'Preview wave'
        : 'Start'
  const isMixCompleted = (mix: IMix) =>
    mix.mixStatusStageId === (MixStageEnum.Completed as number)

  const getMixesForWave = () => {
    const mixes = sortMixes(props.mixesForWave).map((mix) => (
      <div
        data-testid='mix-card'
        key={mix.mixId}
        className='ln-c-card ln-u-push-bottom'
        id={`MixId_${mix.mixId}`}
      >
        {isMixInProgress(mix) === true && (
          <div className='c-bakery-card-status-bar' />
        )}
        <Card>
          <WaveMixCardTitle
            plannerGroupName={
              mix.plannerGroup !== null ? mix.plannerGroup.plannerGroupName : ''
            }
            ovenSettingDetail={mix.ovenSettingDetail}
          />
          <div className='ln-u-push-bottom-sm'>
            {mix.plannerGroup !== null &&
              mix.plannerGroup.displayFlourWeight === true && (
                <div>
                  <span className='ln-u-display-1-fixed'>Yield&nbsp;</span>
                  {getYieldSize(mix)}
                </div>
              )}
          </div>
          {isMixInProgress(mix) === true && (
            <div>
              <GridWrapper>
                <GridItem size={'1/2'}>
                  <OutlinedButton
                    data-testid='mix-button'
                    fullWidth
                    onClick={() => props.handleMixActions(mix)}
                  >
                    View
                  </OutlinedButton>
                </GridItem>
                <GridItem size={'1/2'}>
                  <FilledButton
                    data-testid='mix-button'
                    disabled={mix.updating === true}
                    fullWidth
                    onClick={() =>
                      props.handleMixActions(mix, MixStageEnum.Completed)
                    }
                  >
                    Complete
                  </FilledButton>
                </GridItem>
              </GridWrapper>
            </div>
          )}
          {isMixInPending(mix) === true && (
            <OutlinedButton
              data-testid='mix-button'
              disabled={mix.plan.requiresMixRefresh}
              fullWidth
              onClick={() => props.handleMixActions(mix)}
            >
              {getPendingButtonText(mix)}
            </OutlinedButton>
          )}
          {isMixCompleted(mix) === true && (
            <div>
              <GridWrapper>
                <GridItem size={'1/1'}>
                  <OutlinedButton
                    data-testid='mix-button'
                    fullWidth
                    onClick={() => props.handleMixActions(mix)}
                  >
                    View
                  </OutlinedButton>
                </GridItem>
              </GridWrapper>
            </div>
          )}
        </Card>
      </div>
    ))
    return <div>{mixes}</div>
  }

  return <div>{getMixesForWave()}</div>
}

export default WaveMixCard
