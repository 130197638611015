import { IPlannerGroupItem } from '../../pizza/types/IPlannerGroupItem'
import { IProductionPlan } from '../../pizza/types/IProductionPlan'
import { ActionType } from './reducer'

export interface ISetProductionPlan {
  type: ActionType.production_setProductionPlan
  payload: IProductionPlan
}

export interface ISetProductionPlanOnSave {
  type: ActionType.production_setProductionPlanOnSave
  payload: {
    plannerGroups: IPlannerGroupItem[]
    productionPlan: IProductionPlan
  }
}

export interface ISetAddToTotal {
  type: ActionType.production_setAddToTotalStatus
  payload: boolean
}

export interface ISetYouDefrostedValue {
  type: ActionType.production_setYouDefrostedValue
  payload: {
    plannerGroupIndex: number
    planItemIndex: number
    value: number
  }
}

export interface ISetYouMadeValue {
  type: ActionType.production_setYouMadeValue
  payload: {
    plannerGroupIndex: number
    planItemIndex: number
    value: number
  }
}

export interface ISetProductionisFrozenOpen {
  type: ActionType.production_setIsFrozenOpen
  payload: {
    plannerGroupIndex: number
    value: boolean
  }
}

export interface ISetProductionisOpen {
  type: ActionType.production_setIsOpen
  payload: {
    plannerGroupIndex: number
    value: boolean
  }
}

export const setProductionPlan = (
  productionPlan: IProductionPlan,
): ISetProductionPlan => {
  productionPlan.productionPlannerGroups.forEach((pg) => {
    pg.isOpen = false
    pg.items.forEach((pi) => (pi.youMade = 0))
  })

  productionPlan.frozenProductionPlannerGroups.forEach((pg) => {
    pg.isFrozenOpen = false
    pg.items.forEach((pi) => (pi.youMade = 0))
  })

  return {
    payload: productionPlan,
    type: ActionType.production_setProductionPlan,
  }
}

export const setProductionPlanOnSave = (
  plannerGroups: IPlannerGroupItem[],
  productionPlan: IProductionPlan,
): ISetProductionPlanOnSave => {
  productionPlan.productionPlannerGroups.forEach((pg) => {
    pg.items.forEach((pi) => (pi.youMade = 0))
    const existingPlannerGroup = plannerGroups.find(
      (y) => y.plannerGroupId === pg.plannerGroupId,
    )
    if (existingPlannerGroup !== undefined) {
      pg.isOpen = existingPlannerGroup.isOpen
    }
  })

  productionPlan.frozenProductionPlannerGroups.forEach((pg) => {
    pg.items.forEach((pi) => (pi.youMade = 0))
    const existingPlannerGroup = plannerGroups.find(
      (y) => y.plannerGroupId === pg.plannerGroupId,
    )
    if (existingPlannerGroup !== undefined) {
      pg.isFrozenOpen = existingPlannerGroup.isFrozenOpen
    }
  })

  return {
    payload: { plannerGroups, productionPlan },
    type: ActionType.production_setProductionPlanOnSave,
  }
}

export const setYouDefrostedValue = (
  plannerGroupIndex: number,
  planItemIndex: number,
  value: number,
): ISetYouDefrostedValue => ({
  payload: { plannerGroupIndex, planItemIndex, value },
  type: ActionType.production_setYouDefrostedValue,
})

export const setYouMadeValue = (
  plannerGroupIndex: number,
  planItemIndex: number,
  value: number,
): ISetYouMadeValue => ({
  payload: { plannerGroupIndex, planItemIndex, value },
  type: ActionType.production_setYouMadeValue,
})

export const setAddToTotalStatus = (addToTotal: boolean): ISetAddToTotal => ({
  payload: addToTotal,
  type: ActionType.production_setAddToTotalStatus,
})

export const setProductionisFrozenOpen = (
  plannerGroupIndex: number,
  value: boolean,
): ISetProductionisFrozenOpen => ({
  payload: { plannerGroupIndex, value },
  type: ActionType.production_setIsFrozenOpen,
})

export const setProductionisOpen = (
  plannerGroupIndex: number,
  value: boolean,
): ISetProductionisOpen => ({
  payload: { plannerGroupIndex, value },
  type: ActionType.production_setIsOpen,
})
