import { ApiClient } from '../api/apiClient'
import { isFeatureEnabled } from '../api/featureApi'
import { Feature } from './FeatureEnum'
import { Menu } from './MenuEnum'
import { PlannerName } from './PlannerNameEnum'

export interface HeaderNavItem {
  child?: any
  id: string
  text: string
  to: string
  type: Menu
}

// Bakery
const bakeryProduction: HeaderNavItem = {
  child: undefined,
  id: 'production',
  text: 'Production',
  to: '/bakeryview',
  type: Menu.ProductionView,
}

const bakeryForcast: HeaderNavItem = {
  child: undefined,
  id: 'forecast',
  text: 'Forecast',
  to: '/bakeryforecast',
  type: Menu.ForecastView,
}

const bakeryIngredient: HeaderNavItem = {
  child: undefined,
  id: 'ingredient',
  text: 'Ordering',
  to: '/bakeryingredients',
  type: Menu.Ingredient,
}

const bakeryGOL: HeaderNavItem = {
  child: undefined,
  id: 'gol',
  text: 'GOL',
  to: '/GOLView',
  type: Menu.GOL,
}

const bakeryQpig: HeaderNavItem = {
  child: undefined,
  id: 'qpig',
  text: 'QPIGs',
  to: '/qpiglistview',
  type: Menu.Qpig,
}

// Pizza
const pizzaProduction: HeaderNavItem = {
  child: undefined,
  id: 'production',
  text: 'Production',
  to: '/pizzaview',
  type: Menu.ProductionView,
}

const pizzaForcast: HeaderNavItem = {
  child: undefined,
  id: 'forecast',
  text: 'Forecast',
  to: '/pizzaforecast',
  type: Menu.ForecastView,
}

const pizzaQpig: HeaderNavItem = {
  child: undefined,
  id: 'qpig',
  text: 'QPIGs',
  to: '/pizzaqpiglistview',
  type: Menu.Qpig,
}

const pizzaIngredient: HeaderNavItem = {
  child: undefined,
  id: 'ingredient',
  text: 'Ingredients',
  to: '/pizzaingredients',
  type: Menu.Ingredient,
}

// HotFood
const hotfoodProduction: HeaderNavItem = {
  child: undefined,
  id: 'production',
  text: 'Production',
  to: '/hotfoodview',
  type: Menu.ProductionView,
}

const hotfoodDisplay: HeaderNavItem = {
  child: undefined,
  id: 'display',
  text: 'On display',
  to: '/hotfooddisplay',
  type: Menu.Display,
}

const hotfoodReport: HeaderNavItem = {
  child: undefined,
  id: 'report',
  text: 'Reports',
  to: '/hotfoodreport',
  type: Menu.Report,
}

const hotfoodCounterTemperature: HeaderNavItem = {
  child: undefined,
  id: 'counter-temperature',
  text: 'Counter temperature',
  to: '/hotfoodcountertemperature',
  type: Menu.CounterTemperature,
}

export const headerPlannerTitles = {
  bakery: 'Bakery Planner',
  hotfood: 'Hot Food',
  pizza: 'Pizza Planner',
}

export const GetHeaderMainMenus = async (
  apiClient: ApiClient,
  storeId: number,
  planner: PlannerName,
): Promise<HeaderNavItem[]> => {
  switch (planner) {
    case PlannerName.Bakery:
      const getGolFeatureEnabledTask = isFeatureEnabled(
        apiClient,
        Feature.ShowGolTab,
        storeId,
      )
      const getIngredientsFeatureEnabledTask = isFeatureEnabled(
        apiClient,
        Feature.ShowIsbIngredientsTab,
        storeId,
      )

      const mnu = [bakeryProduction, bakeryForcast]

      await Promise.all([
        getGolFeatureEnabledTask,
        getIngredientsFeatureEnabledTask,
      ])
        .then((responses: [boolean, boolean]) => {
          if (responses[0]) {
            mnu.push(bakeryGOL)
          }
          mnu.push(bakeryQpig)
          if (responses[1]) {
            mnu.push(bakeryIngredient)
          }
        })
        .catch(() => [bakeryProduction, bakeryForcast, bakeryIngredient])

      return mnu

    case PlannerName.HotFood:
      return [
        hotfoodProduction,
        hotfoodDisplay,
        hotfoodCounterTemperature,
        hotfoodReport,
      ]
    case PlannerName.Pizza:
      return [pizzaProduction, pizzaForcast, pizzaQpig, pizzaIngredient]
    default:
      throw new Error('Undefined Planner')
  }
}

export const headerMainMenus = {
  bakeryNavs: [bakeryProduction, bakeryForcast, bakeryQpig, bakeryIngredient],
  hotfoodcounterNavs: [
    hotfoodProduction,
    hotfoodDisplay,
    hotfoodCounterTemperature,
    hotfoodReport,
  ],
  pizzaNavs: [pizzaProduction, pizzaForcast, pizzaQpig, pizzaIngredient],
}
