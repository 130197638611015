import { IMix } from '../types/IMix'

const sortMixesByReadonly = (mixA: IMix, mixB: IMix) => {
  const mixAReadonly = mixA.isReadonly ? 1 : 0
  const mixBReadonly = mixB.isReadonly ? 1 : 0

  return mixAReadonly - mixBReadonly
}

const sortByPositionOnOutput = (mixA: IMix, mixB: IMix) =>
  mixA.positionOnOutput - mixB.positionOnOutput

const sortByOvenSettingPriority = (mixA: IMix, mixB: IMix) => {
  if (mixA.ovenSettingDetail != null && mixB.ovenSettingDetail != null) {
    return (
      mixA.ovenSettingDetail.ovenSettingPriority -
      mixB.ovenSettingDetail.ovenSettingPriority
    )
  }
  return 0
}

const sortMixes = (mixes: IMix[]) => {
  const mixesWithPositionOnOutput = mixes
    .filter((m) => m.ovenSettingDetail == null)
    .sort(sortByPositionOnOutput)
  const mixesWithOvenSettingPriority = mixes
    .filter((m) => m.ovenSettingDetail != null)
    .sort(sortByOvenSettingPriority)
  const sortedMixes = mixesWithPositionOnOutput.concat(
    mixesWithOvenSettingPriority,
  )
  return sortedMixes.sort(sortMixesByReadonly)
}

export default sortMixes
