import { Card, GridItem } from '@jsluna/react'
import React from 'react'

import { TempCheckFor } from '../../enums/TempCheckFor'
import {
  getInstructionDescription,
  getInstructionTitle,
} from '../../utils/CounterInstructionMessages'
import CabinetDrawing from './CabinetDrawing'

interface ICounterInstructionProps {
  isFirstCheck: boolean
  isTurboServe: boolean
  isServeOverInputMode: boolean
  isTurboServeInputMode: boolean
}

const CounterInstruction = (props: ICounterInstructionProps) => {
  const isInputMode =
    (props.isTurboServe && props.isTurboServeInputMode) ||
    (!props.isTurboServe && props.isServeOverInputMode)

  const size = props.isTurboServe ? '1/1' : '2/3'

  return (
    <div className='ln-u-margin-bottom'>
      <GridItem size={{ default: '1/1' }} className='ln-u-hard'>
        <Card className='ln-u-margin-bottom*2 ln-u-padding-right*4 ln-u-padding-top*2 ln-u-hard'>
          <GridItem
            size={{ default: '1/1', md: size, lg: size }}
            data-testid='counter-instruction'
          >
            <div className='ln-u-padding-bottom ln-u-margin-right*4 ln-u-margin-bottom*4'>
              <div className='ln-c-label ln-u-font-weight-bold ln-u-padding-top*2 ln-u-padding-bottom'>
                {getInstructionTitle(
                  props.isFirstCheck
                    ? props.isTurboServe
                      ? TempCheckFor.TurboServeFirstAir
                      : TempCheckFor.CabinetFirstAir
                    : props.isTurboServe
                      ? TempCheckFor.TurboServeRegular
                      : TempCheckFor.CabinetFollowing,
                )}
              </div>
              <br />
              <div
                className='ln-u-font-weight-bold'
                data-testid='counter-instruction-message'
              >
                {isInputMode &&
                  getInstructionDescription(
                    props.isFirstCheck
                      ? props.isTurboServe
                        ? TempCheckFor.TurboServeFirstAir
                        : TempCheckFor.CabinetFirstAir
                      : props.isTurboServe
                        ? TempCheckFor.TurboServeRegular
                        : TempCheckFor.CabinetFollowing,
                  )}
              </div>
            </div>
          </GridItem>

          {/* the cabinet table drawing is only available on the cabinet tab */}
          {isInputMode && !props.isTurboServe && (
            <GridItem
              className='ln-u-padding-top ln-u-margin-left*4'
              size={{ xs: '1/1', sm: '1/2', md: '1/4', lg: '1/4' }}
            >
              <CabinetDrawing />
            </GridItem>
          )}
        </Card>
      </GridItem>
    </div>
  )
}

export default CounterInstruction
