import { PlannerName } from '../enums/PlannerNameEnum'
import { ApiClient } from './apiClient'

export const getUserPreferredPlanner = async (
  apiClient: ApiClient,
): Promise<number> =>
  await apiClient.get<number>(
    PlannerName.None,
    'planner/getuserpreferredplanner',
  )

export const setUserPreferredPlanner = async (
  apiClient: ApiClient,
  preferredPlanner: number,
): Promise<boolean> => {
  const body = `{ "PreferredPlanner" : ${preferredPlanner} }`
  const result = await apiClient.post(
    PlannerName.None,
    'planner/setuserpreferredplanner',
    body,
  )

  return (await result.json()) as boolean
}
