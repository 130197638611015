import { Card, GridItem, Link } from '@jsluna/react'
import React, { useState } from 'react'

import history from '../../../utils/history'
import { setUserPreferredPlanner } from '../../api/plannerApi'
import { useApiClient } from '../../AppContext/appContext'
import { IPlannerSelectionCard } from '../../types/IPlannerSelectionCard'
import SelectFavoriteModal from '../Modals/SelectFavoriteModal'

const PlannerSelectionCard = (props: IPlannerSelectionCard) => {
  const [selectedPlanner, setSelectedPlanner] = useState(-1)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const apiClient = useApiClient()

  const openModal = (planner: number) => {
    if (planner !== -1) {
      setIsModalOpen(true)
      setSelectedPlanner(planner)
    }
  }

  const onModalYes = () => {
    if (selectedPlanner !== -1) {
      setUserPreferredPlanner(apiClient, selectedPlanner)
        .then((res) => {
          if (!res) {
            history.push('/error')
          }
        })
        .catch(() => {
          history.push('/error')
        })
    }
  }

  const onModalNo = () => {
    setIsModalOpen(false)
  }

  const onModalClose = () => {
    setSelectedPlanner(-1)
    setIsModalOpen(false)
  }

  const cardBody = () => (
    <div data-testid={`card${props.plannerType}`}>
      <img
        title={`${props.plannerName}`}
        className='c-selection-image ln-u-flush'
        src={props.plannerIcon}
      />
      <GridItem
        size={'1/1'}
        className='ln-u-text-align-center ln-u-margin-bottom*2 ln-u-hard'
      >
        <h3>{props.plannerName}</h3>
      </GridItem>
    </div>
  )

  return (
    <>
      <GridItem
        size={{ xs: '1/1', sm: '1/2', md: '1/2', lg: '1/2' }}
        className='ln-u-text-align-center ln-u-hard'
      >
        <Card
          className={`ln-u-margin*4 ln-u-flush c-card
          ${selectedPlanner === props.plannerType ? 'c-orange-border' : 'ln-u-border c-unselected-border'}
          ${props.disabled ? 'c-disabled-card' : ''}`}
        >
          {props.disabled ? (
            cardBody()
          ) : (
            <Link
              data-testid={`link${props.plannerType}`}
              onClick={() => openModal(props.plannerType)}
              className='c-common-hover-over-cursor'
            >
              {cardBody()}
            </Link>
          )}
        </Card>
      </GridItem>
      <SelectFavoriteModal
        {...{
          isOpen: isModalOpen,
          linkpath: props.linkPath,
          modalBody:
            "You'll go straight to this planner when you sign in. " +
            'You can switch planner in settings at any time.',
          modalNo: 'No',
          modalTitle: 'Make this your favourite planner?',
          modalYes: 'Yes, make favourite planner',
          onClose: onModalClose,
          onNo: onModalNo,
          onYes: onModalYes,
        }}
      />
    </>
  )
}

export default PlannerSelectionCard
