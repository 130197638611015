import {
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
} from '@jsluna/react'
import React from 'react'

const CleanMixModal = ({
  isOpened,
  setIsOpened,
  handleAction,
}: {
  isOpened: boolean
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  handleAction: () => void
}) => (
  <React.Fragment>
    <Modal
      data-testid='modal'
      open={isOpened}
      handleClose={() => setIsOpened(false)}
    >
      <ModalHeading element='h3' className='ln-u-text-align-center'>
        Are the mixer and relevant belts clean?
      </ModalHeading>
      <div
        className='ln-u-text-align-center ln-o-matrix-list ln-o-matrix-list--spaced
        ln-u-padding-bottom ln-u-padding-right ln-u-padding-left'
      >
        <GridWrapper>
          <GridItem size={{ default: '1/2', ss: '1/3', sm: '1/4' }}>
            <FilledButton
              data-name='confirm-button'
              fullWidth
              className='ln-u-margin'
              onClick={handleAction}
            >
              Yes
            </FilledButton>
          </GridItem>
          <GridItem size={{ default: '1/2', ss: '1/3', sm: '1/4' }}>
            <FilledButton
              data-name='close-button'
              fullWidth
              className='ln-u-margin'
              onClick={() => setIsOpened(false)}
            >
              No
            </FilledButton>
          </GridItem>
        </GridWrapper>
      </div>
    </Modal>
  </React.Fragment>
)

export default CleanMixModal
