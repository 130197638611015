import {
  CancelButton,
  Card,
  Container,
  FilledButton,
  GridItem,
  GridWrapper,
  Link,
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { getCurrentPlanner, getUserStore } from '../../../utils/localStore'
import { getQpigData } from '../../api/qpigApi'
import { getQpigImages } from '../../api/qpigImagesApi'
import { useApiClient } from '../../AppContext/appContext'
import { IQpigImages } from '../../types/IQpigImages'
import { IStoreProduct } from '../../types/IStoreProduct'
import QpigIngredients from './QpigIngredients'
import QpigProcessSteps from './QpigProcessSteps'
import QpigShelfLife from './QpigShelfLife'

const QpigView = (props: RouteComponentProps<{ id: string }>) => {
  const [loading, setLoading] = useState(true)
  const [qpigImages, setQpigImages] = useState<IQpigImages>({
    baseFilePath: '',
    imageFileNames: [],
    signature: '?',
    lastModified: '',
  })
  const [selectedQpigIndex, setSelectedQpigIndex] = useState(-1)
  const [value, setValue] = useState<IStoreProduct>({
    sku: 0,
    description: '',
    qpigs: [],
    shelfLifeComments: [],
  })

  const apiClient = useApiClient()

  const displayQpigHeader = () => (
    <GridWrapper>
      <GridItem size='7/8'>
        <h3 className='ln-u-margin-top*4 ln-u-margin-left*4'>
          {value.description.replace('(Cold)', '').replace('Cold', '')} (
          {value.sku})
        </h3>
      </GridItem>

      <GridItem size='1/8'>
        <div className='ln-u-text-align-right ln-u-margin-top*4 ln-u-margin-right*4'>
          <CancelButton onClick={() => goBack()} />
        </div>
      </GridItem>
    </GridWrapper>
  )

  const displayQpigError = () => (
    <div>
      <GridWrapper>
        {' '}
        <GridItem size='1/2'> </GridItem>
        <GridItem size='1/2'>
          <div className='ln-u-text-align-right ln-u-soft'>
            <CancelButton onClick={() => goBack()} />
          </div>
        </GridItem>
      </GridWrapper>
      <div
        className='ln-u-text-align-center ln-u-soft'
        data-testid='no-qpig-error-info'
        id='noQpigErrorInfo'
      >
        The QPIG data was not found
      </div>
      <div className='ln-u-text-align-center ln-u-soft'>
        <FilledButton className='ln-u-soft-sides' onClick={() => goBack()}>
          {' '}
          Close QPIG{' '}
        </FilledButton>
      </div>
    </div>
  )

  const displaySingleQpig = (qpigIndex: number) => (
    <div>
      {displayQpigHeader()}

      {value.qpigs === null ||
      value.qpigs === undefined ||
      value.qpigs.length === 0 ? (
        <div className='ln-u-text-align-center' id='errorInfo'>
          No Qpig Information
        </div>
      ) : (
        <div className='ln-u-margin-sides*4'>
          <QpigIngredients
            additionalInformation={value.qpigs[qpigIndex].additionalInformation}
          />
        </div>
      )}

      {value.qpigs === null ||
      value.qpigs === undefined ||
      value.qpigs.length === 0 ? (
        <></>
      ) : (
        <div data-testid='qpig-steps' id='qpigSteps'>
          <QpigProcessSteps
            additionalInformation={value.qpigs[qpigIndex].additionalInformation}
            pluCodes={value.qpigs[qpigIndex].pluCodes}
            qpigImages={qpigImages}
          />
        </div>
      )}

      <QpigShelfLife rawShelfLifeComments={value.shelfLifeComments} />

      <div className='ln-u-text-align-center ln-u-soft'>
        <FilledButton className='ln-u-soft-sides' onClick={() => goBack()}>
          {' '}
          Close QPIG{' '}
        </FilledButton>
      </div>
    </div>
  )

  const displayMultipleQpigs = () => (
    <Container>
      {displayQpigHeader()}
      <Card>
        {value.qpigs.map((qpig, qpigIndex) => {
          const planner = getCurrentPlanner()
          const hot =
            qpig.additionalInformation.some(
              (e) => e.type === 'QPIG - Baking',
            ) && planner === 'pizza'

          return (
            <p className='ln-u-margin*2' key={qpigIndex}>
              <Link
                className='ln-u-text-decoration-underline c-common-hover-over-cursor'
                onClick={() => setSelectedQpigIndex(qpigIndex)}
                data-testid='multiple-qpig'
                id='multipleQpig'
              >
                <b>PLU Code(s):</b> {qpig.pluCodes.join(', ')}{' '}
                {hot ? '(Hot)' : ''}
              </Link>
            </p>
          )
        })}
      </Card>
    </Container>
  )

  const displayQPIG = () => {
    if (value === null) {
      return displayQpigError()
    }
    if (selectedQpigIndex !== -1) {
      return displaySingleQpig(selectedQpigIndex)
    }
    if (!value.qpigs || value.qpigs.length <= 1) {
      return displaySingleQpig(0)
    } else {
      return displayMultipleQpigs()
    }
  }

  useEffect(() => {
    const skuId = parseInt(props.match.params.id, 10)
    const storeId = getUserStore().storeId

    const getQpigDataTask = getQpigData(apiClient, storeId, skuId).catch(
      () => ({ qpigs: [], description: '', sku: skuId, shelfLifeComments: [] }),
    )

    const getQpigImagesTask = getQpigImages(apiClient, skuId).catch(() => ({
      signature: '',
      baseFilePath: '',
      imageFileNames: [],
      lastModified: '',
    }))

    Promise.all([getQpigDataTask, getQpigImagesTask])
      .then((result: [IStoreProduct, IQpigImages]) => {
        setValue(result[0])
        setQpigImages(result[1])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const goBack = () => {
    window.history.back()
    window.history.scrollRestoration = 'auto'
  }

  return (
    <Container>
      {loading ? (
        <div className='o-common-loading-overlay__body'>
          <div className='o-common-loading-wrapper'>
            <ProgressIndicator loading preventFocus>
              <ProgressSpinner className='ln-u-margin-right' />
              Loading...
            </ProgressIndicator>
          </div>
        </div>
      ) : (
        <div> {displayQPIG()} </div>
      )}
    </Container>
  )
}

export default QpigView
