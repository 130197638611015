import { InfoCircle } from '@jsluna/icons'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  FlagComponent,
  FlagWrapper,
  Modal,
  ModalHeading,
  OutlinedButton,
} from '@jsluna/react'
import React from 'react'

import { IAlertModalProps } from '../../types/IAlertModalProps'

const NoCounterTempsTakenModal = (props: IAlertModalProps) => (
  <Modal
    small
    handleClose={() => props.handleClose()}
    open={props.isOpen}
    headingId='info-modal'
  >
    <ModalHeading data-testid='modalTitle' element='h4'>
      <FlagWrapper>
        <FlagComponent className='ln-u-margin-top*2 ln-u-margin-right'>
          <InfoCircle className='c-common-tooltip-blue ln-u-display-6-fixed' />
        </FlagComponent>
        <FlagComponent className='ln-u-font-weight-bold ln-u-margin-top*2'>
          Do you know if the displays have come up to temperature?
        </FlagComponent>
      </FlagWrapper>
    </ModalHeading>

    <div>
      You should take counter temperature checks before completing out of oven
      temperature checks, as the cabinets might not be up to temperature.
    </div>

    <ButtonGroupWrapper className='ln-u-text-align-center'>
      <ButtonGroupSecondary className='u-full-width'>
        <FilledButton
          className='u-full-width ln-u-margin-bottom'
          onClick={() => props.handleAction()}
        >
          Take counter temperatures now
        </FilledButton>
        <OutlinedButton
          className='u-full-width'
          onClick={() => props.handleClose()}
        >
          Continue to take out of oven temp checks
        </OutlinedButton>
      </ButtonGroupSecondary>
    </ButtonGroupWrapper>
  </Modal>
)

export default NoCounterTempsTakenModal
