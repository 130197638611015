import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import { getUserStore } from '../../utils/localStore'
import { IForecastPlan } from '../types/IForecastPlan'
import { IUpdatedPlanItems } from '../types/IUpdatedPlanItems'

export const getForecastPlan = async (
  apiClient: ApiClient,
  storeId: number,
  planDate: string,
): Promise<IForecastPlan> =>
  await apiClient.get<IForecastPlan>(
    PlannerName.Pizza,
    `forecast/${storeId}/${planDate}`,
  )

export const updatePizzaPlan = async (
  apiClient: ApiClient,
  planId: number,
  planItems: IUpdatedPlanItems[],
) => {
  const storeCode = getUserStore().storeId
  const body = { planId, planItems, storeCode }

  const result = await apiClient.post(
    PlannerName.Pizza,
    `forecast/${planId}`,
    JSON.stringify(body),
  )
  return (await result.json()) as IResult
}
