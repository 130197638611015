import { useMsal } from '@azure/msal-react'
import { ErrorCircle } from '@jsluna/icons'
import {
  Card,
  FilledButton,
  List,
  ListItem,
  OutlinedButton,
} from '@jsluna/react'
import React from 'react'

import ProductionPlanningHeader from './PlannerSelection/ProductionPlanningHeader'

const NotFoundPage = () => {
  const { instance } = useMsal()

  const onReload = () => {
    window.location.href = window.location.origin
  }

  return (
    <>
      <ProductionPlanningHeader headerMessage={'Production Planning'} />
      <div className='c-common-error_wrapper'>
        <Card className='ln-u-margin-sides*4'>
          <div className='ln-u-margin-top*4'>
            <ErrorCircle
              size='large'
              className='c-common-error_icon ln-u-display-6-fixed'
            />

            <div className='c-common-error_primaryMessage ln-u-display-4-fixed'>
              404 Not Found
            </div>

            <div className='c-common-error_secondaryMessage'>
              Sorry, we cannot found out the requested page.
            </div>

            <div className='c-common-error_secondaryMessage'>
              Please try again.
            </div>

            <div className='ln-u-soft'>
              <List type='matrix' spaced>
                <ListItem>
                  <FilledButton
                    className='ln-u-soft-sides'
                    onClick={() => onReload()}
                  >
                    Back to Production Planning
                  </FilledButton>
                </ListItem>
                <ListItem>
                  <OutlinedButton
                    className='ln-u-soft-sides'
                    onClick={() => void instance.logoutRedirect()}
                  >
                    Logout
                  </OutlinedButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default NotFoundPage
