import React from 'react'
import ExifOrientationImg from 'react-exif-orientation-img'

interface IQpigImageProps {
  baseFilePath: string
  signature: string
  imgFileName: string
}

// QpigImage uses ExifOrientationImg to rotate images to the correct exif orientation.
const QpigImage = ({
  baseFilePath,
  signature,
  imgFileName,
}: IQpigImageProps) => {
  const isIOS = (): boolean =>
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

  const isLocalChrome = (): boolean => {
    /* Chrome blocks ExifOrientationImg's attempt to load the image via javascript due
       to the prescence of localhost in the access origin header, so the image must be
       loaded using the img tag when running the locally. */
    const chromeAgent = window.navigator.userAgent.match('Chrome')
    if (
      chromeAgent !== null &&
      chromeAgent !== undefined &&
      window.location.host.includes('localhost') === true
    ) {
      return true
    }
    return false
  }

  if (isIOS() === true || isLocalChrome() === true) {
    return (
      <img
        data-testid='qpig-image'
        className='c-qpig__image'
        src={`${baseFilePath}/${imgFileName}${signature}`}
        width='100%'
        key={imgFileName}
      />
    )
  }

  return (
    <div className='c-qpig-image-rotation'>
      <div className='c-qpig-image-rotation__inner'>
        <ExifOrientationImg
          className='c-qpig-image-rotation__image'
          crossOrigin='anonymous'
          data-testid='exiforientationimg'
          src={`${baseFilePath}/${imgFileName}${signature}`}
          width='100%'
          key={imgFileName}
        />
      </div>
    </div>
  )
}

export default QpigImage
