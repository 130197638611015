import { IStoreProduct } from '../../common/types/IStoreProduct'
import { PlannerName } from '../enums/PlannerNameEnum'
import { IQpigDataQueryResult } from '../types/IQpigDataQueryResult'
import { ApiClient } from './apiClient'

export const getQpigData = async (
  apiClient: ApiClient,
  storeId: number,
  skuId: number,
): Promise<IStoreProduct> =>
  (
    await apiClient.get<IQpigDataQueryResult>(
      PlannerName.None,
      `skus/qpigs/${storeId}/${skuId}`,
    )
  ).storeProduct
