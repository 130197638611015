import { ErrorCircle } from '@jsluna/icons'
import { FlagComponent, FlagWrapper } from '@jsluna/react'
import React from 'react'

import { FormErrorMessageKey } from '../../enums/FormErrorMessageKey'
import { FormErrorMessages } from '../../utils/FormErrorMessages'

interface TemperatureCheckErrorMessageProps {
  class: string
  messageKey: FormErrorMessageKey
}

const TemperatureCheckErrorMessage = (
  props: TemperatureCheckErrorMessageProps,
) => (
  <FlagWrapper>
    <FlagComponent className={`${props.class}`}>
      <ErrorCircle
        size='large'
        className='ln-u-color-red ln-u-margin-right*1/2'
      />
    </FlagComponent>
    <FlagComponent className={`${props.class}`}>
      {FormErrorMessages.get(props.messageKey)}
    </FlagComponent>
  </FlagWrapper>
)

export default TemperatureCheckErrorMessage
