import {
  FilledButton,
  List,
  ListItem,
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/react'
import React from 'react'

const Loading = ({ message }: { message?: string }) => (
  <>
    <div className='o-common-loading-wrapper ln-u-margin-top*4'>
      <List type='matrix'>
        <ListItem className='ln-u-padding-bottom'>
          <ProgressIndicator loading preventFocus>
            <ProgressSpinner className='ln-u-margin-right' />
          </ProgressIndicator>
        </ListItem>
        <ListItem>
          {`${!message || message === 'none' ? 'Loading' : message.charAt(0).toUpperCase() + message.slice(1)}...`}
        </ListItem>
        <ListItem>
          <FilledButton
            className='ln-u-margin-top*4'
            onClick={() => (window.location.href = window.location.origin)}
          >
            Refresh
          </FilledButton>
        </ListItem>
      </List>
    </div>
  </>
)

export default Loading
