import { Container } from '@jsluna/react'
import React, { useContext, useEffect, useState } from 'react'

import { useApiClient } from '../../../common/AppContext/appContext'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  HeaderNavItem,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { IIngredient } from '../../../common/types/IIngredient'
import { getUserStore } from '../../../utils/localStore'
import {
  getIngredients,
  getIngredientSubCategories,
} from '../../api/ingredientsApi'
import { ISubCategory } from '../../types/ISubCategory'
import IngredientTable from './IngredientTable'

const IngredientView = () => {
  const { state, dispatch } = useContext(Context)
  const apiClient = useApiClient()

  const [errorMessage, setErrorMessage] = useState('')
  const [headerNavigationItems, setHeaderNavigationItems] = useState(
    headerMainMenus.bakeryNavs,
  )
  const [isLoading, setIsLoading] = useState(true)
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([])
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)

  const [ingredients, setIngredients] = useState<IIngredient[]>()

  useEffect(() => {
    setIsLoading(true)

    dispatch(setPlanner(PlannerName.Bakery))

    const storeId = parseInt(getUserStore().storeCode, 10)

    const getHeaderMainMenusTask = GetHeaderMainMenus(
      apiClient,
      storeId,
      PlannerName.Bakery,
    )
    const getIngredientsTask = getIngredients(apiClient, storeId)
    const getIngredientSubCategoriesTask = getIngredientSubCategories(
      apiClient,
      storeId,
    )
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    // Do not change the order of the promises in the Promise.all
    // Once the default tutorial not related with a feature is rendered,
    // it will not re-render again so all set states should be done before tutorial token is set
    Promise.all([
      getHeaderMainMenusTask,
      getTutorialTokenTask,
      getIngredientSubCategoriesTask,
      getIngredientsTask,
    ])
      .then(
        (
          responses: [
            HeaderNavItem[],
            string | null,
            ISubCategory[],
            IIngredient[],
          ],
        ) => {
          setHeaderNavigationItems(responses[0])
          setTutorialToken(responses[1])
          setSubCategories(responses[2])
          setIngredients(responses[3])
        },
      )
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e)
        }
        setErrorMessage(
          `${ErrorMessage.IngredientsView.FailedToLoad} - ${(e as Error).message}`,
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleIngredientChecked = (ingredientId: number) => {
    if (!ingredients) {
      return
    }

    const clone = ingredients.map((x) => ({ ...x }))
    const toBeUpdated = clone.find((x) => x.ingredientId === ingredientId)!
    toBeUpdated.checked = !toBeUpdated.checked
    setIngredients(clone)
  }

  const handleSelectAll = (subCategoryId: number) => {
    if (!ingredients) {
      return
    }

    const clone = ingredients.map((x) => ({ ...x }))
    clone.forEach((x) => {
      if (x.subCategoryId === subCategoryId) {
        x.checked = true
      }
    })
    setIngredients(clone)
  }

  const handleDeselectAll = () => {
    if (!ingredients) {
      return
    }

    const clone = ingredients.map((x) => ({ ...x }))
    clone.forEach((x) => (x.checked = false))
    setIngredients(clone)
  }

  const renderIngredientTable = () =>
    subCategories.length > 0 &&
    ingredients &&
    ingredients.length > 0 && (
      <IngredientTable
        handleIngredientChecked={handleIngredientChecked}
        handleDeselectAll={handleDeselectAll}
        handleSelectAll={handleSelectAll}
        ingredients={ingredients}
        initialTab={subCategories[0].subCategoryId}
        subCategories={subCategories}
      />
    )

  return (
    <>
      <div className='c-common-hide-in-print'>
        {!tutorialToken ? (
          <Loading message='Tutorial Loading' />
        ) : (
          <TutorialWrapper state={state} token={tutorialToken}>
            <Header
              title={headerPlannerTitles.bakery}
              navItems={headerNavigationItems}
              activeMenuType={Menu.Ingredient}
            />
            <Container soft className='ln-u-push-top-sm'>
              <div className='c-common-main-view-content'>
                <h4>Ordering for USEORD</h4>
                <div className='ln-u-margin-top*4 ln-u-margin-bottom*4'>
                  View the minimum amount of cases you'll need for each SKU
                  below, to produce your forecast. Select the required SKUs to
                  print a list, then complete your orders on USEORD. Any editing
                  of forecasts will not be reflected here until next day.
                </div>
                {renderIngredientTable()}
              </div>

              {isLoading && <Loading />}
              {errorMessage && <ErrorRefresh message={errorMessage} />}
            </Container>
          </TutorialWrapper>
        )}
      </div>

      <div className='c-common-only-visible-in-print'>
        {renderIngredientTable()}
      </div>
    </>
  )
}

export default IngredientView
