import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import { IBatchItemDisplay } from '../types/IBatchItemDisplay'
import { ISkuHeader } from '../types/ISkuHeader'
import { ISoldOutRequest } from '../types/ISoldOutRequest'

const getBatchItems = async (
  apiClient: ApiClient,
  storeCode: number,
  batchDate: Date,
): Promise<IBatchItemDisplay[]> =>
  await apiClient.get<IBatchItemDisplay[]>(
    PlannerName.HotFood,
    `display/${storeCode}/${batchDate.toDateString()}`,
  )

const getPizzaAndIsbProducts = async (
  apiClient: ApiClient,
  storeId: number,
): Promise<ISkuHeader[]> =>
  await apiClient.get<ISkuHeader[]>(
    PlannerName.HotFood,
    `sku/pizzaAndIsbProducts/${storeId}`,
  )

const setBatchItemSoldOut = async (
  apiClient: ApiClient,
  request: ISoldOutRequest,
): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(
    PlannerName.HotFood,
    'display/setSoldOut',
    body,
  )
  return (await result.json()) as IResult
}

export { getBatchItems, getPizzaAndIsbProducts, setBatchItemSoldOut }
