import { PlannerName } from '../enums/PlannerNameEnum'
import { ApiClient } from './apiClient'

export const isFeatureEnabled = async (
  apiClient: ApiClient,
  featureId: number,
  storeId: number,
): Promise<boolean> =>
  await apiClient.get<boolean>(
    PlannerName.None,
    `feature/${featureId}/${storeId}/isfeatureenabled`,
  )
