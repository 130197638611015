import { IStore } from '../common/types/IStore'

const getUserStore = (): IStore => {
  const userStore = localStorage.getItem('USER_STORE')
  return userStore !== null
    ? (JSON.parse(userStore) as IStore)
    : ({ storeId: 0, storeCode: '', storeName: '' } as IStore)
}

const setUserStore = (store: IStore) =>
  localStorage.setItem('USER_STORE', JSON.stringify(store))

const setCurrentPlanner = (planner: string) =>
  localStorage.setItem('CURRENT_PLANNER', planner)

const getCurrentPlanner = () => localStorage.getItem('CURRENT_PLANNER')

export { getCurrentPlanner, getUserStore, setCurrentPlanner, setUserStore }
