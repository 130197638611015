import '../../../scss/common/components/qpig.scss'

import {
  GridItem,
  GridWrapper,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import React from 'react'

import { IAdditionalInformation } from '../../types/IAdditionalInformation'
import {
  getColumnText,
  getRowIdentifier,
  getValueFromField,
  hasTableFormatData,
} from './QpigUtils'

interface IQpigProcessStepsTableProps {
  additionalInformationList: IAdditionalInformation[]
}

const QpigProcessStepsTable = ({
  additionalInformationList,
}: IQpigProcessStepsTableProps) => {
  const getQpigTable = (isResponsive: boolean) => {
    if (!hasTableFormatData(additionalInformationList)) {
      return
    }

    const rows: string[][] = []
    const rawRows: string[] = []

    const getRows = () => {
      const columns: string[] = []
      additionalInformationList
        .filter((additionalInfo) => hasTableFormatData([additionalInfo]))
        .sort((a: IAdditionalInformation, b: IAdditionalInformation) =>
          getRowIdentifier(a) < getRowIdentifier(b) ? -1 : 1,
        )
        .forEach((additionalInfo, row) => {
          const fields = getColumnText(additionalInfo.description).split(',')
          fields.forEach((field) => {
            const isolatedColumnName = field
              .substring(0, field.indexOf(':'))
              .trim()
            const isolatedColumnValue = getValueFromField(field)
            const matchedColumn = columns.find((c) => c === isolatedColumnName)
            if (matchedColumn === undefined) {
              columns.push(isolatedColumnName)
            }
            if (rows.length < row + 1) {
              rows.push([])
              rawRows.push(additionalInfo.description)
            }
            rows[row].push(isolatedColumnValue)
          })
        })

      const tableSize = () => {
        switch (columns.length) {
          case 1:
            return '1/4'
          case 2:
            return '1/2'
          case 3:
            return '2/3'
          default:
            return '1/1'
        }
      }

      return (
        <div className='ln-u-margin-top'>
          <GridWrapper data-testid='grid'>
            <GridItem size={tableSize()}>
              <TableContainer responsive={isResponsive}>
                <TableHeader>
                  <TableRow
                    data-testid={isResponsive ? 'process-steps-header-row' : ''}
                  >
                    {columns.map((column, colNumber) => (
                      <TableCell key={colNumber}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {rows.map((row, rowNumber) => {
                    if (columns.length !== row.length) {
                      return (
                        <TableRow
                          data-testid={isResponsive ? 'process-steps-row' : ''}
                          key={rowNumber}
                        >
                          <TableCell key={`${rowNumber}`}>
                            {rawRows[rowNumber]}
                          </TableCell>
                        </TableRow>
                      )
                    }
                    return (
                      <TableRow
                        data-testid={isResponsive ? 'process-steps-row' : ''}
                        key={rowNumber}
                      >
                        {row.map((cell, cellNumber) => (
                          <TableCell
                            label={columns[cellNumber]}
                            key={`${rowNumber}_${cellNumber}`}
                          >
                            {cell}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </TableContainer>
            </GridItem>
          </GridWrapper>
        </div>
      )
    }

    return getRows()
  }

  return (
    <div className='c-table-responsive'>
      <div className='c-table-responsive__mobile c-qpig-table-margin'>
        {getQpigTable(true)}
      </div>
      <div className='c-table-responsive__desktop c-qpig-table-margin'>
        {getQpigTable(false)}
      </div>
    </div>
  )
}

export default QpigProcessStepsTable
