export enum BatchTray {
  None = 0,
  Top = 1,
  Middle = 2,
  Bottom = 3,
}

export enum CabinetWindow {
  CustomerRight = 1,
  CustomerLeft = 2,
  YouLeft = 3,
  YouRight = 4,
}

export enum TurboShelf {
  Top = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
}
