import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IProductionPlan } from '../types/IProductionPlan'

export const getProductionPlan = async (
  apiClient: ApiClient,
  storeCode: number,
): Promise<IProductionPlan> =>
  await apiClient.get<IProductionPlan>(
    PlannerName.Pizza,
    `production/${storeCode}`,
  )
