import { Minus, Plus } from '@jsluna/icons'
import { IconButton } from '@jsluna/react'
import React, { useContext } from 'react'

import { Context } from '../../../common/Context/context'
import {
  setYouDefrostedValue,
  setYouMadeValue,
} from '../../../common/Context/productionDispatch'
import { IItem } from '../../types/IItem'

interface IProps {
  isFrozen: boolean
  planItem: IItem
  planItemIndex: number
  plannerGroupIndex: number
}

const Incrementor = (props: IProps) => {
  const { state, dispatch } = useContext(Context)

  const value = props.isFrozen
    ? state.production.frozenProductionPlannerGroups[props.plannerGroupIndex]
        .items[props.planItemIndex].youMade
    : state.production.productionPlannerGroups[props.plannerGroupIndex].items[
        props.planItemIndex
      ].youMade

  const isMinusDisabled = () => value === -props.planItem.quantityCompleted
  const isPlusDisabled = () => value === 50

  return (
    <>
      <div className='c-common-incrementor__button'>
        <IconButton
          variant='outlined'
          label='decrement'
          hideLabel
          disabled={isMinusDisabled()}
          onClick={() =>
            props.isFrozen
              ? dispatch(
                  setYouDefrostedValue(
                    props.plannerGroupIndex,
                    props.planItemIndex,
                    value - 1,
                  ),
                )
              : dispatch(
                  setYouMadeValue(
                    props.plannerGroupIndex,
                    props.planItemIndex,
                    value - 1,
                  ),
                )
          }
        >
          <Minus />
        </IconButton>
      </div>
      <div className='c-common-inline ln-u-margin-sides*2 c-common-fixed-value-width ln-u-text-align-center'>
        {value}
      </div>
      <div className='c-common-incrementor__button'>
        <IconButton
          variant='outlined'
          label='increment'
          hideLabel
          disabled={isPlusDisabled()}
          onClick={() =>
            props.isFrozen
              ? dispatch(
                  setYouDefrostedValue(
                    props.plannerGroupIndex,
                    props.planItemIndex,
                    value + 1,
                  ),
                )
              : dispatch(
                  setYouMadeValue(
                    props.plannerGroupIndex,
                    props.planItemIndex,
                    value + 1,
                  ),
                )
          }
        >
          <Plus fixed />
        </IconButton>
      </div>
    </>
  )
}

export default Incrementor
