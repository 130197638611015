import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../utils/localStore'
import { IControlGroup } from '../types/IControlGroup'

const getControlGroups = async (
  apiClient: ApiClient,
): Promise<IControlGroup[]> =>
  await apiClient.get<IControlGroup[]>(
    PlannerName.Bakery,
    `ControlGroup/${getUserStore().storeId}`,
  )

export { getControlGroups }
