import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import { getUserStore } from '../../utils/localStore'
import { IPlan } from '../types/IPlan'
import { IPlanItemUpdate } from '../types/IPlanItemUpdate'

const getPlan = async (
  apiClient: ApiClient,
  planDate: string,
): Promise<IPlan> =>
  await apiClient.get<IPlan>(
    PlannerName.Bakery,
    `plan/${getUserStore().storeId}/${planDate}`,
  )

const updatePlanItems = async (
  apiClient: ApiClient,
  planId: number,
  planItemUpdateList: IPlanItemUpdate[],
) => {
  const body = `{"planId": ${planId}, "planItemUpdateList":${JSON.stringify(planItemUpdateList)}}`
  const result = await apiClient.post(
    PlannerName.Bakery,
    'plan/updatePlanItems',
    body,
  )
  return (await result.json()) as IResult
}

export { getPlan, updatePlanItems }
