import React from 'react'

import { MixStageEnum } from '../../enums/MixStageEnum'
import { IMix } from '../../types/IMix'

interface IProps {
  mix: IMix
  allMixSkusMarked: boolean
  handleCheckAll: () => void
}

const MixSkusTableHeader = (props: IProps) => {
  const mix = props.mix
  if (
    props.mix !== null &&
    props.mix !== undefined &&
    props.mix.mixStatusStageId === (MixStageEnum.InProgress as number)
  ) {
    return (
      <tr className='ln-c-table__row ln-c-table__header-row'>
        <th className='ln-c-table__cell c-table__cell--checkbox ln-u-hard'>
          <div className='ln-c-form-option--checkbox'>
            <input
              className='ln-c-form-option__input'
              type='checkbox'
              id='select-all'
              name='select-all'
              checked={props.allMixSkusMarked}
              onChange={props.handleCheckAll}
            />
            <label
              className='ln-c-form-option__label'
              htmlFor='select-all'
            ></label>
          </div>
        </th>
        <th className='ln-c-table__header-cell c-table__cell--type-sku'>
          Type/SKU
        </th>
        <th className='ln-c-table__header-cell c-table__cell--production-target ln-u-flush-left'>
          Production target
        </th>
        <th className='ln-c-table__header-cell c-table__cell--production-actual ln-u-soft-left'>
          Actual production
        </th>
      </tr>
    )
  }

  if (
    props.mix !== null &&
    props.mix !== undefined &&
    props.mix.mixStatusStageId === (MixStageEnum.Completed as number)
  ) {
    return (
      <tr className='ln-c-table__row ln-c-table__header-row'>
        <th className='ln-c-table__header-cell c-table__cell--60'>Type/SKU</th>
        <th className='ln-c-table__header-cell c-table__cell--20'>
          Production target
        </th>
        <th className='ln-c-table__header-cell c-table__cell--20'>
          Actual production
        </th>
      </tr>
    )
  }

  if (mix !== undefined) {
    return (
      <tr className='ln-c-table__row ln-c-table__header-row'>
        <th className='ln-c-table__header-cell c-table__cell--60'>Type/SKU</th>
        <th className='ln-c-table__header-cell c-table__cell--40'>
          Production target
        </th>
      </tr>
    )
  }

  return <div />
}

export default MixSkusTableHeader
