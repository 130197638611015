import {
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  OutlinedButton,
} from '@jsluna/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { IModal } from '../../types/IModal'

const SelectFavoriteModal = (props: IModal) => {
  const [openModal, setIsModalOpen] = useState(false)

  useEffect(() => {
    setIsModalOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <React.Fragment>
      <Modal open={openModal} handleClose={() => props.onClose()}>
        <ModalHeading data-testid='modalTitle' element='h4'>
          {props.modalTitle}
        </ModalHeading>
        <div>
          {props.modalBody && (
            <div data-testid='modalBody'>{props.modalBody}</div>
          )}
          <GridWrapper className='ln-u-margin-top*4'>
            <GridItem size={{ xs: '1/1', ss: '1/2' }}>
              <Link
                tabIndex={-1}
                to={props.linkpath ? props.linkpath : '/error'}
              >
                <FilledButton
                  className='ln-u-margin-bottom*2'
                  data-testid='onYes'
                  fullWidth
                  onClick={() =>
                    props.onYes === undefined ? null : props.onYes()
                  }
                >
                  {props.modalYes}
                </FilledButton>
              </Link>
            </GridItem>
            <GridItem size={{ xs: '1/1', ss: '1/2' }}>
              <Link
                tabIndex={-1}
                to={props.linkpath ? props.linkpath : '/error'}
              >
                <OutlinedButton
                  data-testid='onNo'
                  fullWidth
                  onClick={() =>
                    props.onNo === undefined ? null : props.onNo()
                  }
                >
                  {props.modalNo}
                </OutlinedButton>
              </Link>
            </GridItem>
          </GridWrapper>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SelectFavoriteModal
