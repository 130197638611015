export const ErrorMessage = {
  BatchDisplayView: {
    FailedToLoad: 'An error occurred whilst attempting to load display',
    FailedToSoldOut:
      'An error occurred whilst attempting to remove a batch item',
  },
  CounterTemperatureView: {
    FailedToLoad:
      'An error occurred whilst attempting to load counter temperatures',
  },
  ForecastView: {
    FailedToLoad: 'An error occurred whilst attempting to load forecast',
    FailedToUpdate: 'An error occurred whilst attempting to update data',
  },
  GeneralException: `An error occurred.
                     Please check network connection, click Refresh and try again`,
  IngredientsView: {
    FailedToLoad: 'An error occurred whilst attempting to load ingredients',
  },
  ProductionView: {
    FailedToLoad: 'An error occurred whilst attempting to load production',
    FailedToUpdate: 'An error occurred whilst attempting to update data',
  },
  ReportView: {
    FailedToLoad: 'An error occurred whilst attempting to load reports',
  },
  SaveException: `An error occurred whilst attempting to save.
                  Please check network connection, click Refresh and try again`,
  ServerException: {
    SomethingWrong: 'Please try again later',
  },
  QpigView: {
    FailedToLoad: 'An error occurred whilst attempting to load QPIG list',
  },
  GOLView: {
    FailedToLoad: 'An error occurred whilst attempting to load GOL Picklist',
  },
}
