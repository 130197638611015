import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IIngredient } from '../../common/types/IIngredient'

export const getIngredients = async (
  apiClient: ApiClient,
  storeId: string,
): Promise<IIngredient[]> =>
  await apiClient.get<IIngredient[]>(
    PlannerName.Pizza,
    `ingredients/${storeId}`,
  )
