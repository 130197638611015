import {
  Card,
  GridItem,
  GridWrapper,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TabLink,
  Tabs,
} from '@jsluna/react'
import moment from 'moment'
import React, { useContext, useState } from 'react'

import AppTooltip from '../../../common/components/Tooltip/AppTooltip'
import { Context } from '../../../common/Context/context'
import { Unit } from '../../../common/enums/UnitEnum'
import { IDailyIngredientQuantity } from '../../../common/types/IDailyIngredientQuantity'
import { IIngredient } from '../../../common/types/IIngredient'
import { roundUpToTwoDecimalPlace } from '../../../common/utils/roundUpToTwoDecimalPlace'
import { IngredientSubcat } from '../../enums/IngredientSubcatEnum'

const IngredientTable = () => {
  const { state } = useContext(Context)

  const tabs = [
    { name: 'Ambient & frozen', key: 'frozen' },
    { name: 'Fresh', key: 'fresh' },
  ]
  const [currentTab, setCurrentTab] = useState('frozen')

  const totalCasesTooltip = (
    <AppTooltip
      message={
        "This is the total number of cases you'll need for each SKU for the dates shown."
      }
    />
  )
  const date = (days: number) => moment().add(days, 'days').format('ddd DD/MM')

  const ingredientTitle = (ingredient: IIngredient) => {
    switch (ingredient.unitId) {
      case Unit.Units:
        return (
          <>
            ({ingredient.ingredientId}) {ingredient.ingredientName} -{' '}
            {ingredient.packSize} x {ingredient.unitSize} unit
          </>
        )
      case Unit.Grams:
        return (
          <>
            ({ingredient.ingredientId}) {ingredient.ingredientName} -{' '}
            {ingredient.packSize} x {ingredient.unitSize}g
          </>
        )
    }
  }

  const getDailyQuantities = (
    dailyIngredient: IDailyIngredientQuantity[],
    totalCaseUnits: number,
  ) => {
    const dailyQuantities = new Array<number | string>(7).fill('TBC')
    dailyIngredient.map(
      (dailyQuantity: IDailyIngredientQuantity, i: number) => {
        dailyQuantities[i] = roundUpToTwoDecimalPlace(
          dailyQuantity.quantity / totalCaseUnits,
        )
      },
    )
    return dailyQuantities
  }

  const total = (
    dailyIngredients: IDailyIngredientQuantity[],
    totalCaseUnits: number,
  ) => {
    let res = 0
    dailyIngredients.map(
      (dailyQuantity) =>
        (res += parseFloat(
          roundUpToTwoDecimalPlace(dailyQuantity.quantity / totalCaseUnits),
        )),
    )
    return roundUpToTwoDecimalPlace(res)
  }

  const dayAndQuantity = (dailyQuantity: any[], num: number) => (
    <>
      <GridItem
        size={'1/2'}
        className='ln-u-font-weight-bold ln-u-margin-bottom'
      >
        {date(num)}
      </GridItem>
      <GridItem size={'1/2'} className='ln-u-text-align-center'>
        {dailyQuantity[num]}
      </GridItem>
    </>
  )

  const ingredientCard = (isFrozenIngredients: boolean) =>
    state.ingredients
      .filter((x) => x.isFrozen === isFrozenIngredients)
      .sort((ingredientA: IIngredient, ingredientB: IIngredient): number =>
        ingredientA.positionOnOutput < ingredientB.positionOnOutput ? -1 : 1,
      )
      .map((ingredient: IIngredient, key: number) => {
        const dailyQuantity = getDailyQuantities(
          ingredient.dailyIngredients,
          ingredient.totalCaseUnits,
        )
        return (
          <div className='c-pizza-card-container c-pizza-card-row' key={key}>
            <div className='ln-u-soft ln-u-font-weight-bold'>
              {ingredientTitle(ingredient)}
            </div>
            <GridItem
              size={'1/2'}
              className='ln-u-font-weight-bold ln-u-margin-bottom'
            >
              {moment().format('ddd DD/MM')}
            </GridItem>
            <GridItem size={'1/2'} className='ln-u-text-align-center'>
              {dailyQuantity[0]}
            </GridItem>
            {dayAndQuantity(dailyQuantity, 1)}
            {dayAndQuantity(dailyQuantity, 2)}
            {dayAndQuantity(dailyQuantity, 3)}
            {dayAndQuantity(dailyQuantity, 4)}
            {dayAndQuantity(dailyQuantity, 5)}
            {dayAndQuantity(dailyQuantity, 6)}
            <GridItem
              size={'1/2'}
              className='ln-u-font-weight-bold ln-u-soft-bottom'
            >
              Total cases
            </GridItem>
            <GridItem size={'1/2'} className='ln-u-text-align-center'>
              {total(ingredient.dailyIngredients, ingredient.totalCaseUnits)}
            </GridItem>
          </div>
        )
      })

  const getIngredientsTable = (isFrozenIngredients: boolean) =>
    state.ingredients
      .filter((x) => x.isFrozen === isFrozenIngredients)
      .sort((ingredientA: IIngredient, ingredientB: IIngredient): number =>
        ingredientA.positionOnOutput < ingredientB.positionOnOutput ? -1 : 1,
      )
      .map((ingredient, key) => {
        const dailyQuantity = getDailyQuantities(
          ingredient.dailyIngredients,
          ingredient.totalCaseUnits,
        )
        return (
          <TableRow className='ln-space-half' key={key}>
            <TableCell
              className='c-table__cell--pizza-type-sku'
              data-testid='ingredientTitle'
            >
              {ingredientTitle(ingredient)}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty1'
            >
              {dailyQuantity[0]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty2'
            >
              {dailyQuantity[1]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty3'
            >
              {dailyQuantity[2]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty4'
            >
              {dailyQuantity[3]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty5'
            >
              {dailyQuantity[4]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty6'
            >
              {dailyQuantity[5]}
            </TableCell>
            <TableCell
              className='c-table__cell--day-of-week'
              data-testid='qty7'
            >
              {dailyQuantity[6]}
            </TableCell>
            <TableCell
              className='c-table__cell--total-cases'
              data-testid='ingredientTotal'
            >
              {total(ingredient.dailyIngredients, ingredient.totalCaseUnits)}
            </TableCell>
          </TableRow>
        )
      })

  const ingredientTable = (subcat: IngredientSubcat) => (
    <div className='c-pizza-table-container ln-u-soft-bottom'>
      <TableContainer fixed>
        <TableHeader>
          <TableRow>
            <TableCell className='c-table__cell--ingredient-type-sku'>
              SKU/units per case
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate1'
            >
              {moment().format('ddd DD/MM')}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate2'
            >
              {date(1)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate3'
            >
              {date(2)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate4'
            >
              {date(3)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate5'
            >
              {date(4)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate6'
            >
              {date(5)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-quantity'
              data-testid='ingDate7'
            >
              {date(6)}
            </TableCell>
            <TableCell
              className='c-table__cell--ingredient-total-cases'
              data-testid='totalCases'
            >
              Total cases{totalCasesTooltip}
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {getIngredientsTable(subcat === IngredientSubcat.AmbientAndFrozen)}
        </TableBody>
      </TableContainer>
    </div>
  )

  const toggleTab = () => {
    switch (currentTab) {
      case 'frozen':
        return (
          <>
            {ingredientTable(IngredientSubcat.AmbientAndFrozen)}{' '}
            {ingredientCard(true)}
          </>
        )
      case 'fresh':
        return (
          <>
            {ingredientTable(IngredientSubcat.Fresh)} {ingredientCard(false)}
          </>
        )
      default:
        break
    }
  }

  const displayTabs = () => (
    <Tabs>
      {tabs.map((tab) => (
        <TabLink
          key={tab.key}
          onClick={() => setCurrentTab(tab.key)}
          active={currentTab === tab.key}
        >
          {tab.name}
        </TabLink>
      ))}
    </Tabs>
  )

  return (
    <Card>
      <GridWrapper>
        <GridItem size={'1/1'} className='ln-u-hard-bottom'>
          {displayTabs()}
        </GridItem>
        <GridItem size={'1/1'}>{toggleTab()}</GridItem>
      </GridWrapper>
    </Card>
  )
}

export default IngredientTable
