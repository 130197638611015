import moment from 'moment'

const getDates = () => {
  const planDates = []
  for (let i = -6; i <= 6; i++) {
    planDates.push(moment().add(i, 'days'))
  }

  return planDates
}

const getTodaysDate = () => moment().format('YYYY/M/D')

export { getDates, getTodaysDate }
